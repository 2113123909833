<template>
  <div class="container">
    <div id="header">
      <a-button class="space btn" shape="round" @click="animationBtnClick()">
        {{ animationBtnText }}
      </a-button>
      <input
        v-model.lazy.number="currentFrame"
        class="space"
        type="range"
        step="1"
        :min="minFrame"
        :max="maxFrame"
        @change="sliderClick()"
      />
      <label class="space time-label">{{ currentDate }}</label>
      <a-button class="space btn" shape="round" @click="rotationBtnClick()">
        {{ rotationBtnText }}
      </a-button>
    </div>
    <div id="graph" ref="graph"></div>
    <a-space id="left" direction="vertical" align="center">
      <a-radio-group v-model:value="timeRange">
        <a-radio-button :value="1">6个月</a-radio-button>
        <a-radio-button :value="2">2年</a-radio-button>
        <a-radio-button :value="3">全部</a-radio-button>
      </a-radio-group>
      <a-space id="amount-container" class="transparent border" direction="vertical" align="center">
        <div class="amount">当前数字资产量</div>
        <div id="amount-value" class="amount">{{ capital }}</div>
      </a-space>
      <div id="chart1" ref="chart1" class="transparent chart border"></div>
      <div id="chart6" ref="chart6" class="transparent chart border"></div>
    </a-space>
    <a-space id="right" direction="vertical" align="center">
      <a-radio-group v-model:value="rightActiveTab">
        <a-radio-button :value="1">网络规模</a-radio-button>
        <a-radio-button :value="2">网络结构</a-radio-button>
      </a-radio-group>
      <a-space v-show="rightActiveTab === 1" direction="vertical">
        <div id="chart2" ref="chart2" class="chart transparent border"></div>
        <div id="chart3" ref="chart3" class="chart transparent border"></div>
        <div id="chart4" ref="chart4" class="chart transparent border"></div>
        <div id="chart5" ref="chart5" class="chart transparent border"></div>
      </a-space>
      <a-space v-show="rightActiveTab === 2" direction="vertical">
        <a-table
          class="holes transparent border"
          :columns="structualHoleColumns"
          :data-source="structualHoles"
          :scroll="{ y: 200 }"
          :pagination="false"
          :custom-row="structualHoleRow"
          :show-header="false"
        >
          <template #title>
            <a-space>
              <span class="table-title transparent">结构洞</span>
              <a-switch
                v-model:checked="showStructualHole"
                @click="updateGraphNodeColor()"
              ></a-switch>
            </a-space>
          </template>
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'id'">
              <a-typography-text :ellipsis="true" :content="record.id" />
            </template>
            <template v-else-if="column.key === 'date'">
              <span>{{ formatDate(record.timestamp) }}</span>
            </template>
          </template>
        </a-table>

        <a-table
          class="holes transparent border"
          :columns="communityColumns"
          :data-source="currentCommunitySizes"
          :custom-row="communityRow"
          :custom-header-row="communityHeaderRow"
          :scroll="{ y: 200 }"
          :pagination="false"
        >
          <template #title>
            <span class="table-title transparent">当前社群数量 {{ currentCommunityCount }}</span>
          </template>
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'id'">
              <span>{{ record.id + 1 }}</span>
            </template>
            <template v-else-if="column.key === 'size'">
              <span>{{ record.size }}</span>
            </template>
          </template>
        </a-table>

        <a-table
          class="holes transparent border"
          :columns="bridgeColumns"
          :data-source="bridgesData"
          :scroll="{ y: 200 }"
          :pagination="false"
          :custom-row="bridgeRow"
          :show-header="false"
        >
          <template #title>
            <span class="table-title transparent">桥链接</span>
          </template>
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'link'">
              <a-typography-text
                :ellipsis="true"
                :content="`${record.link1.source} ${record.link2.source}`"
              />
            </template>
            <template v-else-if="column.key === 'date'">
              <span>{{ formatDate(record.timestamp) }}</span>
            </template>
          </template>
        </a-table>
      </a-space>
    </a-space>
  </div>
</template>

<script setup>
  import ForceGraph3D from '3d-force-graph';
  import axios from 'axios';
  import dayjs from 'dayjs';
  import * as echarts from 'echarts';
  import { computed, onMounted, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import normData from './norm_data.json';
  import normParams from './norm_params.json';

  const route = useRoute();
  const uuid = route.path.split('/').at(-1);

  const BASE_URL = 'https://ip-enterprise-api.yuanben.site';
  // const BASE_URL = 'http://localhost:8080';
  const client = axios.create({ baseURL: BASE_URL });

  const getTimeLines = async () => {
    let page = 1;
    let total = 0;
    const timestamps = [];

    do {
      const resp = await client.get(`/v1/score/timeline/${uuid}`, {
        params: {
          page: page,
        },
      });
      timestamps.push(...resp.data.data);
      if (total === 0) {
        total = resp.data.total;
      }
      page++;
    } while (timestamps.length < total);

    return timestamps;
  };

  const fetchTimeLines = async () => {
    timelines.value = await getTimeLines();
    maxFrame.value = timelines.value.length;
  };

  const getScores = async () => {
    let page = 1;
    let total = 0;
    const data = [];

    do {
      const resp = await client.get(`/v1/score/${uuid}`, {
        params: {
          page: page,
        },
      });
      data.push(...resp.data.data);
      if (total === 0) {
        total = resp.data.total;
      }
      page++;
    } while (data.length < total);

    return data;
  };

  const fetchScores = async () => {
    const scores = await getScores();
    matrices.value = scores.map((score) => {
      return {
        time: score.timestamp * 1000,
        capital: Math.ceil(score.capital).toFixed(),
        amount: score.amount,
        flow: score.flow,
        density: score.density,
        centralization: score.centralization,
      };
    });
  };

  const getGraphData = async ({ limit, start = undefined, end = undefined }) => {
    const params = {
      page_size: limit,
    };
    if (start) {
      params.start = start;
    }
    if (end) {
      params.end = end;
    }

    const resp = await client.get(`/v1/score/graph/${uuid}`, {
      params: params,
    });
    return resp.data.data;
  };

  const storeGraphData = (graphData) => {
    for (const frame of graphData) {
      data.value.set(frame.timestamp, frame);
    }
  };

  const getCommunities = async ({ limit, start = undefined, end = undefined }) => {
    const params = {
      page_size: limit,
    };
    if (start) {
      params.start = start;
    }
    if (end) {
      params.end = end;
    }

    const resp = await client.get(`/v1/score/community/${uuid}`, {
      params: params,
    });
    return resp.data.data;
  };

  const storeCommunities = (communities) => {
    for (const frame of communities) {
      const communityCount = frame.communities.length;
      const nodeCommunityIndex = new Map();
      const sizes = [];
      for (const [index, community] of frame.communities.entries()) {
        for (const node of community) {
          nodeCommunityIndex.set(node, index);
        }
        sizes.push({
          id: index,
          size: community.length,
        });
      }
      communitySizes.value.set(frame.timestamp, sizes);

      const graphData = data.value.get(frame.timestamp);
      graphData.communityCount = communityCount;
      for (const node of graphData.nodes) {
        if (nodeCommunityIndex.has(node.id)) {
          node.communityIndex = nodeCommunityIndex.get(node.id);
        }
      }
    }
  };

  const fetchGraph = async ({ limit, start = undefined, end = undefined }) => {
    const [graphData, communities] = await Promise.all([
      getGraphData({ limit: limit, start: start, end: end }),
      getCommunities({ limit: limit, start: start, end: end }),
    ]);
    storeGraphData(graphData);
    storeCommunities(communities);
  };

  const getStructualHoles = async () => {
    let page = 1;
    let total = 0;
    const structualHoles = [];

    do {
      const resp = await client.get(`/v1/score/structual_hole/${uuid}`, {
        params: {
          page: page,
        },
      });
      structualHoles.push(...resp.data.structual_holes);
      if (total === 0) {
        total = resp.data.total;
      }
      page++;
    } while (structualHoles.length < total);

    return structualHoles;
  };

  const storeStructualHoles = (holes) => {
    structualHoles.value = holes;
    for (const hole of holes) {
      const id = hole.id;
      const timestamp = hole.timestamp;
      if (!structualHoleMap.value.has(timestamp)) {
        structualHoleMap.value.set(timestamp, []);
      }
      structualHoleMap.value.get(timestamp).push(id);
    }
  };

  const fetchStructualHoles = async () => {
    const structualHoles = await getStructualHoles();
    storeStructualHoles(structualHoles);
  };

  const getBridges = async () => {
    let page = 1;
    let total = 0;
    const bridges = [];

    do {
      const resp = await client.get(`/v1/score/bridge/${uuid}`, {
        params: {
          page: page,
        },
      });
      bridges.push(...resp.data.bridges);
      if (total === 0) {
        total = resp.data.total;
      }
      page++;
    } while (bridges.length < total);

    return bridges;
  };

  const storeBridges = async (bridges) => {
    bridgesData.value = bridges;
  };

  const fetchBridges = async () => {
    const bridges = await getBridges();
    storeBridges(bridges);
  };

  const timelines = ref([]);
  const matrices = ref([]);
  const data = ref(new Map());
  const structualHoles = ref([]);
  const structualHoleMap = ref(new Map());
  const bridgesData = ref([]);
  const communitySizes = ref(new Map());

  const currentFrame = ref(0);
  const minFrame = ref(0);
  const maxFrame = ref(100);

  const currentTimestamp = computed(() => {
    const frame = Math.min(currentFrame.value, timelines.value.length - 1);
    const timestamp = timelines.value[frame];
    return timestamp;
  });

  const formatDate = (timestamp) => {
    const date = dayjs.unix(timestamp);
    return date.format('YYYY-MM-DD');
  };

  const currentDate = computed(() => {
    const frame = Math.min(currentFrame.value, timelines.value.length - 1);
    const timestamp = timelines.value[frame];
    return formatDate(timestamp);
  });

  const graph = ref(null);
  const Graph = ForceGraph3D();

  const repoColor = '#33FF00'; //"#85A894";
  const developerColor = '#0099FF'; //"#FCE4A8";
  const holeColor = '#FF3030';
  const communityColor = '#F5E02A';
  const linkColor = '#85A894';
  const bridgeColor = '#FF0033';
  const cameraDistance = 800;

  const calculateNodeRadius = (nodes, category, maxRadius, minRadius) => {
    let max = 0;

    nodes.forEach((node) => {
      if (node.category === category) {
        max = max < node.weight ? node.weight : max;
      }
    });

    for (let i = 0; i < nodes.length; i++) {
      let node = nodes[i];
      if (node.category === category) {
        node.radius = (node.weight / max) * maxRadius;
        node.radius = node.radius < minRadius ? minRadius : node.radius;
      }
    }
  };

  const maxRepoNodeRadius = 40;
  const maxDeveloperNodeRadius = 15;
  const minNodeRadius = 5;

  const findStructualHole = (nodes) => {
    const currentHoles = structualHoleMap.value.get(currentTimestamp.value) || [];

    for (const node of nodes) {
      if (currentHoles.includes(node.id)) {
        node.hole = true;
      } else {
        node.hole = false;
      }
    }
  };
  // add data to graph
  const addFrameData = (frameData) => {
    calculateNodeRadius(frameData.nodes, 'repo', maxRepoNodeRadius, minNodeRadius);
    calculateNodeRadius(frameData.nodes, 'developer', maxDeveloperNodeRadius, minNodeRadius);
    findStructualHole(frameData.nodes);

    const { nodes } = Graph.graphData();
    const oldNodes = new Map(nodes.map((d) => [d.id, d]));
    const newNodes = frameData.nodes.map((d) => Object.assign(oldNodes.get(d.id) || {}, d));

    Graph.graphData({ nodes: newNodes, links: frameData.links });
  };

  // control whether to add new data to graph automatically
  const continueAnimation = ref(true);
  // control whether to rotate the graph
  const continueRotation = ref(false);

  const animationBtnText = computed(() => {
    if (continueAnimation.value) {
      return 'Pause Animation';
    } else {
      return 'Start Animation';
    }
  });

  const rotationBtnText = computed(() => {
    if (continueRotation.value) {
      return 'Pause Rotation';
    } else {
      return 'Start Rotation';
    }
  });

  const animationBtnClick = () => {
    continueAnimation.value = !continueAnimation.value;
    continueRotation.value = !continueAnimation.value;
  };

  const rotationBtnClick = () => {
    continueRotation.value = !continueRotation.value;
    if (continueRotation.value) {
      continueAnimation.value = false;
    }
  };

  const sliderClick = () => {
    continueAnimation.value = false;
    continueRotation.value = true;
  };

  watch(continueAnimation, () => {
    if (continueAnimation.value) {
      currentFrame.value++;
      showCommunity.value = false;
      showBridge.value = false;
    }
  });

  // add data to graph when currentFrame change and continueAnimation is true;
  watch(currentFrame, async (newFrame, oldFrame) => {
    if (newFrame < oldFrame) {
      Graph.d3ReheatSimulation();
    }
    if (newFrame < timelines.value.length) {
      const start = timelines.value[newFrame];
      if (!data.value.has(start)) {
        await fetchGraph({ start: start, limit: 30 });
      }
      const frame = data.value.get(start);
      addFrameData(frame);
    }
  });

  // rotation effect
  let angle = 0;
  setInterval(() => {
    if (continueRotation.value) {
      Graph.cameraPosition({
        x: cameraDistance * Math.sin(angle),
        z: cameraDistance * Math.cos(angle),
      });
      angle += Math.PI / 1080;
    }
  }, 10);

  const updateGraphNodeColor = () => {
    Graph.nodeColor(Graph.nodeColor());
  };

  const updateGraphLinkColor = () => {
    Graph.linkColor(Graph.linkColor());
  };

  onMounted(async () => {
    await Promise.all([
      fetchTimeLines(),
      fetchScores(),
      fetchGraph({ limit: 30 }),
      fetchStructualHoles(),
      fetchBridges(),
    ]);

    Graph(graph.value)
      .height(window.innerHeight > 1000 ? window.innerHeight : 1000)
      .nodeColor((d) => {
        if (showStructualHole.value && d.hole) {
          return holeColor;
        } else if (showCommunity.value && d.communityIndex == currentCommunityIndex.value) {
          return communityColor;
        } else {
          return d.category === 'repo' ? repoColor : developerColor;
        }
      })
      .nodeLabel((d) => {
        if (d.category === 'developer') {
          return `<div class="node-label">
            <div>${d.id}</div>
            <div>
              <span>限制度：</span>
              <span>${d.constraint.toFixed(2)}</span>
            </div>
            </div>`;
        } else {
          return `<div class="node-label">
            <div>${d.id}</div>
            </div>`;
        }
      })
      .nodeOpacity(1)
      .nodeResolution(16)
      .nodeVal((d) => d.radius)
      .linkColor((link) => {
        if (showBridge.value) {
          const source = typeof link.source === 'string' ? link.source : link.source.id;
          const target = typeof link.target === 'string' ? link.target : link.target.id;

          const linkStr = `${source}|${target}`;
          if (currentBridgeLinks.value.includes(linkStr)) {
            return bridgeColor;
          } else {
            return linkColor;
          }
        } else {
          return linkColor;
        }
      })
      .linkOpacity(1)
      .linkWidth('4px')
      .linkResolution(12)
      .linkCurvature(0.5)
      .warmupTicks(20)
      .cooldownTime(1000)
      .cameraPosition({ z: cameraDistance });

    Graph.d3Force('charge').strength(-20);
    Graph.d3Force('link').distance(150);

    Graph.onEngineStop(() => {
      const { nodes, links } = Graph.graphData();
      nodes.forEach((node) => {
        node.fx = node.x;
        node.fy = node.y;
        node.fz = node.z;
        node.vx = node.vy = node.vz = 0;
      });
      if (currentFrame.value.length < timelines.value.length) {
        const timestamp = timelines.value[currentFrame.value];
        if (data.value.has(timestamp)) {
          const frame = data.value.get(timestamp);
          frame.nodes = nodes;
          frame.links = links;
        }
      }
      if (continueAnimation.value) {
        currentFrame.value++;
      }
    });
    addFrameData(data.value.get(timelines.value[0]));
  });

  const timeRange = ref(1);
  const capital = computed(() => {
    if (matrices.value.length === 0) {
      return 0;
    }
    const frame = Math.min(currentFrame.value, matrices.value.length - 1);
    return matrices.value[frame].capital;
  });

  const chart1 = ref(null);
  const chart2 = ref(null);
  const chart3 = ref(null);
  const chart4 = ref(null);
  const chart5 = ref(null);
  const chart6 = ref(null);

  const echartsMap = new Map();

  const setChartData = (chartRef, title, data, dimensions) => {
    const chartId = chartRef.value.id;
    if (chartRef.value && !echartsMap.has(chartRef.value)) {
      echartsMap.set(chartRef.value, echarts.init(chartRef.value));
    }
    const chart = echartsMap.get(chartRef.value);
    const option = {
      title: {
        text: title,
        left: 'center',
        top: 20,
        textStyle: {
          color: '#000000',
          fontSize: chartId === 'chart1' ? 24 : 18,
        },
      },
      dataset: {
        source: data,
        dimensions: dimensions,
      },
      xAxis: {
        type: 'time',
        splitNumber: 3,
        axisLabel: {
          formatter: '{yy}-{M}-{d}',
          rotate: 30,
        },
      },
      yAxis: { type: 'value', splitNumber: 3 },
      grid: {
        containLabel: true,
        top: chartId === 'chart1' ? 80 : 55,
        bottom: chartId === 'chart1' ? 20 : 10,
      },
      series: [{ type: 'line', smooth: true, animation: false }],
    };
    chart.setOption(option);
  };

  const computeZ = (val) => {
    const mean = normParams.mean;
    const std = normParams.std;
    const z = (val - mean) / std;
    return z;
  };

  const findP = (z) => {
    let i = 0;
    while (i < normData.length && normData[i].x < z) {
      i++;
    }
    let p = 1;
    if (i < normData.length) {
      p = normData[i].p;
    }
    return (p * 100).toFixed(2);
  };

  const setChart6 = (capital) => {
    const z = computeZ(capital);
    if (chart6.value && !echartsMap.has(chart6.value)) {
      echartsMap.set(chart6.value, echarts.init(chart6.value));
    }
    const chart = echartsMap.get(chart6.value);
    const option = {
      title: {
        text: `资产优质概率: ${findP(z)}%`,
        left: 'center',
        top: 20,
        textStyle: {
          color: '#000000',
          fontSize: 18,
        },
      },
      visualMap: {
        type: 'piecewise',
        show: false,
        dimension: 0,
        seriesIndex: 0,
        pieces: [
          {
            gt: -4,
            lt: z,
            color: 'rgba(0, 0, 180, 0.4)',
          },
        ],
      },
      dataset: {
        source: normData,
        dimensions: ['x', 'y'],
      },
      xAxis: {
        type: 'value',
        min: -4,
        max: 4,
        axisLabel: { show: false },
        splitLine: { show: false },
      },
      yAxis: { type: 'value', min: 0, max: 0.6 },
      grid: {
        containLabel: true,
        top: 55,
        bottom: 20,
      },
      series: [
        {
          type: 'line',
          smooth: true,
          symbol: 'none',
          animation: false,
          lineStyle: {
            color: '#5470C6',
            width: 2,
          },

          areaStyle: {},
        },
      ],
    };
    chart.setOption(option);
  };

  const frameLimit = 50;
  const filterMatrices = (matrices, start, end) => {
    if (timeRange.value === 1) {
      start = start > end - 26 ? start : end - 26;
    } else if (timeRange.value === 2) {
      start = start > end - 104 ? start : end - 104;
    }

    if (start + frameLimit < end) {
      const interval = (end - start) / frameLimit;
      const indices = [];
      for (let i = 0; i < frameLimit; i++) {
        indices.push(Math.round(end - 1 - i * interval));
      }
      return indices.reverse().map((i) => matrices[i]);
    } else {
      return matrices.slice(start, end);
    }
  };

  const updateMatrices = () => {
    const end = Math.min(currentFrame.value + 1, maxFrame.value);
    const source = filterMatrices(matrices.value, minFrame.value, end);
    if (chart1.value !== null) {
      setChartData(chart1, '数字资产趋势图', source, ['time', 'capital']);
    }
    if (chart2.value !== null) {
      setChartData(chart2, '资产存量', source, ['time', 'amount']);
    }
    if (chart3.value !== null) {
      setChartData(chart3, '资产流量', source, ['time', 'flow']);
    }
    if (chart4.value !== null) {
      setChartData(chart4, '资产密度', source, ['time', 'density']);
    }
    if (chart5.value !== null) {
      setChartData(chart5, '资产中心度', source, ['time', 'centralization']);
    }
    if (chart6.value !== null) {
      let capital = 0;
      if (source.length > 0) {
        capital = source.at(-1).capital;
      }
      setChart6(capital);
    }
  };

  watch([timeRange, currentFrame], updateMatrices);

  onMounted(() => {
    updateMatrices();
  });

  const rightActiveTab = ref(1);

  watch(rightActiveTab, (value) => {
    if (value === 1) {
      updateMatrices();
    }
  });

  const structualHoleColumns = [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID',
    },
    {
      key: 'date',
      dataIndex: 'date',
      title: '日期',
    },
  ];

  const structualHoleRow = (record, index) => {
    const res = {
      class: 'small-table-row',
      onclick: () => {
        const frame = timelines.value.indexOf(record.timestamp);
        currentFrame.value = frame;
        showStructualHole.value = true;
        continueAnimation.value = false;
        continueRotation.value = true;
        updateGraphNodeColor();
      },
    };
    if (index % 2 === 1) {
      res.class += ' table-strpped-row';
    }
    return res;
  };

  const showStructualHole = ref(false);
  watch(showStructualHole, (value) => {
    if (value) {
      showCommunity.value = false;
    }
  });

  const communityColumns = [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID',
    },
    {
      key: 'size',
      dataIndex: 'size',
      title: '大小',
    },
  ];

  const currentCommunityCount = computed(() => {
    const frame = Math.min(currentFrame.value, timelines.value.length - 1);
    const timestamp = timelines.value[frame];
    const graphData = data.value.get(timestamp);
    let count = 0;
    if (graphData) {
      count = graphData.communityCount || 0;
    }
    if (count > 1) {
      return count.toString();
    } else {
      return '';
    }
  });

  const currentCommunitySizes = computed(() => {
    const frame = Math.min(currentFrame.value, timelines.value.length - 1);
    const timestamp = timelines.value[frame];
    const res = communitySizes.value.get(timestamp) || [];
    if (res.length > 1) {
      return res;
    } else {
      return [];
    }
  });

  const showCommunity = ref(false);
  const currentCommunityIndex = ref(0);
  const communityRow = (record, index) => {
    const res = {
      class: 'small-table-row',
      onclick: () => {
        currentCommunityIndex.value = record.id;
        showCommunity.value = true;
        continueAnimation.value = false;
        updateGraphNodeColor();
      },
    };
    if (index % 2 === 1) {
      res.class += ' table-strpped-row';
    }
    return res;
  };

  const communityHeaderRow = () => {
    return {
      class: 'small-table-header',
    };
  };

  watch(showCommunity, (value) => {
    if (value) {
      showStructualHole.value = false;
    }
  });

  const bridgeColumns = [
    {
      key: 'link',
      dataIndex: 'link',
      title: '桥链接',
    },
    {
      key: 'date',
      dataIndex: 'date',
      title: '日期',
    },
  ];

  const bridgeRow = (record, index) => {
    const res = {
      class: 'small-table-row',
      onclick: () => {
        const frame = timelines.value.indexOf(record.timestamp);
        currentFrame.value = frame;
        currentBridge.value = record;
        showBridge.value = true;
        continueAnimation.value = false;
        updateGraphLinkColor();
      },
    };
    if (index % 2 === 1) {
      res.class += ' table-strpped-row';
    }
    return res;
  };

  const showBridge = ref(false);
  const currentBridge = ref(null);

  const currentBridgeLinks = computed(() => {
    if (currentBridge.value) {
      return [
        `${currentBridge.value.link1.source}|${currentBridge.value.link1.target}`,
        `${currentBridge.value.link2.source}|${currentBridge.value.link2.target}`,
      ];
    } else {
      return [];
    }
  });
</script>

<style scoped>
  .container {
    position: relative;
  }

  #header {
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .space {
    margin: 0 5px;
  }

  .btn {
    width: 150px;
  }

  .time-label {
    font-size: 16px;
    font-weight: 500;
    color: white;
  }

  #left {
    position: absolute;
    left: 20px;
    top: 8px;
    z-index: 4;
  }

  #right {
    position: absolute;
    right: 20px;
    top: 8px;
    z-index: 4;
  }

  .border {
    border: 1px solid rgb(200, 200, 200);
  }

  .chart {
    width: 300px;
    height: 220px;
  }

  .holes {
    width: 300px;
    height: fit-content;
  }

  .transparent {
    background-color: rgba(244, 244, 244, 0.8);
  }

  :deep(.transparent) {
    background-color: rgba(244, 244, 244, 0.8);
  }

  :deep(.ant-table) {
    background-color: rgba(244, 244, 244, 0.8);
  }

  :deep(.table-strpped-row) td {
    background-color: white;
  }

  :deep(.small-table-row) td {
    padding: 8px 8px;
  }

  :deep(.small-table-header) th {
    padding: 8px 8px;
  }

  #amount-container {
    margin-top: 10px;
    width: 300px;
    padding-top: 20px;
  }

  .amount {
    color: #5470c6;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }

  #amount-value {
    font-size: 80px;
    line-height: 180px;
  }

  .table-title {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }

  :deep(.node-label) {
    color: white;
    font-size: 12px;
    padding: 1px 4px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
  }
</style>
