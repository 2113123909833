<template>
  <a-row type="flex" align="middle" style="height: 100px; flex-wrap: nowrap">
    <a-col flex="0 1 80px"></a-col>
    <a-col flex="1 0 auto">
      <a-row
        type="flex"
        justify="space-between"
        align="middle"
        style="height: 100px; flex-wrap: nowrap"
      >
        <a-col>
          <img
            ref="logoImageRef"
            :src="logoImage"
            :style="{ width: logoWidth }"
            @click="clickWebTitle"
          />
        </a-col>
        <a-col>
          <a-space :size="rightGap" align="center">
            <router-link
              v-if="config.serviceSystem"
              :to="{ path: '/' }"
              class="chain-link nav-button"
              :style="{ color: color }"
            >
              {{ config.serviceSystem }}
            </router-link>
            <router-link
              v-if="config.corporationCompany"
              :to="{ name: 'partner' }"
              class="chain-link nav-button"
              :style="{ color: color }"
            >
              {{ config.corporationCompany }}
            </router-link>
            <a
              :href="`${$appGlobal.CHAIN_BROWSER_URL}${$appGlobal.CHAIN_BROWSER_HOME}`"
              target="_blank"
              class="chain-link nav-button"
              :style="{ color: color }"
            >
              {{ blockBrowseText }}
            </a>
            <router-link
              :to="{ name: 'verifyFile' }"
              target="_blank"
              class="chain-link nav-button"
              :style="{ color: color }"
            >
              {{ config.fileVerifyCenterText }}
            </router-link>
            <template v-if="showUser">
              <a-button
                v-if="showLogin && config.frontPageVersion == 'default'"
                class="chain-link"
                style="height: 50px; border-radius: 12px; width: 132px"
                :style="{ color: loginColor, background: loginBgColor }"
                @click="clickLogin"
              >
                登录/注册
              </a-button>
              <a-button
                v-else-if="showLogin"
                class="chain-link xa-login-button"
                style="height: 50px; border-radius: 12px; width: 132px"
                :style="{ color: loginColor, background: loginBgColor }"
                @click="clickLogin"
              >
                登录/注册
              </a-button>
            </template>
          </a-space>
        </a-col>
      </a-row>
    </a-col>
    <a-col flex="0 1 80px"></a-col>
  </a-row>
</template>

<script setup>
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import config from '/config';
  import { computed, onMounted, ref } from 'vue';
  import CompanyAPI from '@/api/v1/company';
  import V2CompanyAPI from '@/api/v2/company';

  const props = defineProps({
    showLogin: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    showUser: {
      type: Boolean,
      default: true,
    },
    isBlack: {
      type: Boolean,
      default: false,
    },
  });
  const store = useStore();
  const router = useRouter();
  const logoImageRef = ref();
  const logoImage = computed(() => {
    if (config.frontPageVersion == 'xian') {
      return require('./images/xian-logo.png');
    } else if (config.navbarLogoImageName == 'hetu_navbar_logo.png') {
      return require('./images/hetu_logo.png');
    } else {
      return require('@/assets/' + config.navbarLogoImageName);
    }
  });
  const logoWidth = ref('0px');
  const color = computed(() => (props.isBlack ? 'black' : 'white'));
  const loginColor = computed(() => (props.isBlack ? 'white' : 'black'));
  const loginBgColor = computed(() => (props.isBlack ? '#456ff4' : '#e1eafc'));
  const rightGap = computed(() => {
    if (store.state.screenSize.width > 1200) {
      return 30;
    } else if (store.state.screenSize.width > 980) {
      return 16;
    } else {
      return 16;
    }
  });
  const blockBrowseText = computed(() =>
    config.frontPageVersion == 'default' ? '区块链浏览器' : '资产网络',
  );
  const clickWebTitle = () => {
    if (config.frontPageVersion !== 'xian') {
      if (store.state.user.phonenumber) {
        router.push({ name: config.defaultPage });
      } else {
        window.location.reload();
      }
    }
  };
  const clickLogin = () => {
    if (store.state.user.phonenumber) {
      router.push({ name: config.defaultPage });
    } else {
      router.push({ name: 'login', params: { active: 'login' } });
    }
  };
  onMounted(() => {
    let api = CompanyAPI;
    if (config.realAuthNeedOCR) {
      api = CompanyAPI;
    } else {
      api = V2CompanyAPI;
    }
    api
      .getCompany()
      .then((response) => {
        store.commit('company/SET_COMPANY', response);
      })
      .catch(() => {});
  });
  onMounted(() => {
    let image = new Image();
    image.src = logoImageRef.value.src;
    image.onload = () => {
      logoWidth.value = image.width / 2 + 'px';
    };
  });
</script>

<style lang="less" scoped>
  .chain-link {
    font-size: 18px;
  }
  .chain-link:hover {
    color: @text-selection-bg;
  }
  .xa-login-button:hover {
    background: white !important;
  }
  .nav-button:hover {
    color: #ffffff80 !important;
  }
</style>
