<template>
  <div style="width: 100%; height: 100%">
    <iframe :src="htmlPath" width="100%" height="100%" style="border: 0"></iframe>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  const htmlPath = ref(`/${process.env.VUE_APP_VERSION}/screen/index.html`);
</script>
