import { USER_COMPANY_ROLE_CONFIDENTIAL } from '@/model/role';

const SET_COMPANY = 'SET_COMPANY';
const CREATE_COMPANY = 'CREATE_COMPANY';
const JOIN_COMPANY = 'JOIN_COMPANY';
const CANCEL_TRUST = 'CANCEL_TRUST';
const REVOKE_IDENTIFY = 'REVOKE_IDENTIFY';
const SET_COMPANIES = 'SET_COMPANIES';
const CLEAR_COMPANIES = 'CLEAR_COMPANIES';
const SET_INVITED_COMPANY = 'SET_INVITED_COMPANY';

const state = {
  name: '',
  verified: '',
  license: '',
  public_key: '',
  is_admin: '',
  role: null,
  private_key_status: '',
  member_confirmed_status: '', // 1-通过  2-待审核  3-拒绝
  status: '', // 1-正常 2-记录存在未加入平台 3-未激活 4-失效  5-吊销  6-正在生成数字身份 7数字身份失败
  integrals: 0,
  certificate: '',
  certificate_dna: '',
  companies: [],
  invitation_code: '',
  invited_company: undefined,
};

const mutations = {
  [SET_COMPANY](state, payload) {
    Object.assign(state, payload);
  },
  [SET_INVITED_COMPANY](state, payload) {
    Object.assign(state, payload);
  },
  [CREATE_COMPANY](state, payload) {
    Object.assign(state, payload);
  },
  [JOIN_COMPANY](state, payload) {
    Object.assign(state, payload);
  },
  [REVOKE_IDENTIFY](state) {
    state.private_key_status = 4;
  },
  [CANCEL_TRUST](state) {
    state.private_key_status = 2;
  },
  [SET_COMPANIES](state, payload) {
    state.companies = payload;
  },
  [CLEAR_COMPANIES](state) {
    Object.assign(state, {
      name: '',
      verified: '',
      license: '',
      public_key: '',
      is_admin: '',
      role: null,
      private_key_status: '',
      member_confirmed_status: '',
      status: '',
      integrals: 0,
      certificate: '',
      certificate_dna: '',
      companies: [],
      invitation_code: '',
      invited_company: undefined,
    });
  },
};

const getters = {
  company_confidential: (state) => state.company.role === USER_COMPANY_ROLE_CONFIDENTIAL,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
