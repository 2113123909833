<template>
  <div class="auth-page">
    <authComponent v-if="config.realAuthNeedOCR" />
    <card-auth-component v-else />
  </div>
</template>
<script>
  import UserModel from '@/model/user';
  import config from '/config';
  import CompanyModel from '@/model/company';
  export default {
    beforeRouteEnter(to, from, next) {
      console.log('beforeRouteEnter');
      UserModel.getMyUserInfo()
        .then((user) => {
          if (user !== null) {
            CompanyModel.getJoinedCompanies().then((companies) => {
              if (companies.length > 0) {
                CompanyModel.getCurrentCompanyInfo().then((company) => {
                  if (company.name) {
                    next({ name: config.defaultPage });
                  } else {
                    next({ name: 'invitedCompany' });
                  }
                });
              } else {
                next();
              }
            });
          } else {
            next({ name: 'login', params: { active: 'login' } });
          }
        })
        .catch((error) => {
          console.log(error);
          next({ name: 'login', params: { active: 'login' } });
        });
    },
  };
</script>

<script setup>
  import AuthComponent from './ocr-auth/index.vue';
  import CardAuthComponent from './id-auth/index.vue';
</script>

<style scoped></style>
