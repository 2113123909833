<template>
  <div class="index-div">
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script>
  import UserModel from '@/model/user';
  export default {
    name: 'IndexPage',
    beforeRouteEnter(to, from, next) {
      UserModel.getMyUserInfo()
        .then(() => {})
        .catch(() => {});
      next();
    },
    data() {
      return {};
    },
    methods: {},
  };
</script>

<style scoped>
  .index-div {
    height: 100%;
  }
</style>
