<template>
  <div class="auth-content">
    <div class="id-image-content">
      <a-space direction="vertical" :size="16" align="center" style="width: 100%">
        <div></div>
        <a-typography-title :level="4">
          {{ $t('请使用与手机号一致的身份信息进行实名认证') }}
        </a-typography-title>
        <div style="height: 10px"></div>
        <a-form
          ref="formRef"
          :model="authForm"
          :label-col="{ style: { width: '100px' } }"
          :rules="authRules"
          style="width: 500px"
        >
          <a-form-item label="身份证号" name="card_no">
            <a-input
              v-model:value="authForm.card_no"
              :placeholder="i18nText('请输入身份证号')"
              size="large"
            ></a-input>
          </a-form-item>
          <a-form-item label="姓名" name="name">
            <a-input
              v-model:value="authForm.name"
              :placeholder="i18nText('请输入姓名')"
              size="large"
            ></a-input>
          </a-form-item>
          <a-form-item label="">
            <div class="button-box">
              <a-button
                class="commit-button"
                :loading="loading"
                type="primary"
                size="large"
                @click="commitAuth"
              >
                {{ $t('认证') }}
              </a-button>
            </div>
          </a-form-item>
        </a-form>
      </a-space>
    </div>
  </div>
</template>

<script setup>
  import { computed, onMounted, reactive, ref } from 'vue';
  import { useStore } from 'vuex';
  import UserAPI from '@/api/v2/users';
  import UserModel from '@/model/user';
  import ErrorMessage from '@/model/errorMessage';
  import { i18nText } from '@/lang';

  const emit = defineEmits(['finishRealAuth']);
  const store = useStore();
  const user = computed(() => store.state.user);
  const loading = ref(false);
  const authForm = reactive({ name: '', card_no: '' });

  const userIdCardNum = computed(() => store.state.user.card_no);
  const formRef = ref();
  let validateID = async (_rule, value) => {
    const str = value.trim();
    const regex = /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}(\d|X|x)$/;
    if (!regex.test(str)) {
      return Promise.reject(i18nText('请输入身份证号码'));
    } else {
      return Promise.resolve();
    }
  };

  let validateName = async (_rule, value) => {
    if (!value) {
      return Promise.reject(i18nText('请输入姓名'));
    } else {
      return Promise.resolve();
    }
  };

  const errorResponse = reactive({
    error: {},
  });

  let errorResponseValidate = async (_rule) => {
    let field = ErrorMessage.getMessageFieldNameFromError(errorResponse.error);
    if (field) {
      if (_rule.field === field) {
        let error = ErrorMessage.getServerMessageFromError(errorResponse.error);
        let show = i18nText('网络异常,请稍后重试');
        if (error == 'verified_identity_invalid') {
          show = i18nText('实名信息校验不一致，请核对确认后重新认证');
        }
        return Promise.reject(show);
      }
    }
    return Promise.resolve();
  };

  const authRules = {
    card_no: [
      { required: true, trigger: 'submit', validator: validateID },
      { required: true, trigger: 'submit', validator: errorResponseValidate },
    ],
    name: [
      { required: true, trigger: 'submit', validator: validateName },
      { required: true, trigger: 'submit', validator: errorResponseValidate },
    ],
  };
  const commitAuth = async () => {
    try {
      errorResponse.error = {};
      await formRef.value.validate();
      await UserAPI.commitUserAuth(authForm.card_no, authForm.name);
      await UserModel.getMyUserInfo();
    } catch (e) {
      errorResponse.error = e;
      formRef.value.validate();
      console.log(e);
    }
    if (userIdCardNum.value) {
      emit('finishRealAuth');
    }
  };
  onMounted(() => {
    authForm.phonenumber = user.value.phonenumber;
  });
</script>

<style scoped>
  .button-box {
    text-align: center;
  }
  :deep(.ant-form-item-required) {
    margin-top: 4px;
    font-size: 18px;
  }
</style>
