<template>
  <div class="footer">
    <div class="footer-corner">
      <img src="./images/line.png" style="width: 241px; height: 175px" />
    </div>
    <div style="height: 58px"></div>
    <a-row justify="center">
      <a-typography-title :level="3" style="color: white">数字知识财产</a-typography-title>
    </a-row>
    <div style="height: 52px"></div>
    <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
      <a-col flex="15px"></a-col>
      <a-col flex="1 1 auto">
        <a-space direction="vertical" style="width: 100%">
          <a-row type="flex">
            <a-col v-for="(item, index) in footers" :key="index" :span="24">
              <a-space direction="vertical" :size="12">
                <a-typography-title :level="4" style="color: white">
                  {{ item.title }}
                </a-typography-title>
                <div style="height: 12px"></div>
                <a-typography-text
                  v-for="(children, index) in item.children"
                  :key="index"
                  style="color: white; font-size: 18px"
                  @click="clickFooter(children)"
                >
                  {{ children.text }}
                </a-typography-text>
                <div style="height: 70px"></div>
              </a-space>
            </a-col>
            <a-col :span="24">
              <img
                src="./images/qr_code.png"
                style="
                  width: 156px;
                  height: 156px;
                  background: #315aff;
                  border: 12px solid #315aff;
                  border-radius: 12px;
                "
              />
            </a-col>
          </a-row>
        </a-space>
      </a-col>
      <a-col flex="15px"></a-col>
    </a-row>
    <div style="height: 90px"></div>
    <a-divider style="background-color: #ffffff1a" />
    <a-row type="flex" justify="start" style="flex-wrap: nowrap; display: none">
      <a-col flex="15px"></a-col>
      <a-col flex="1 1 auto">
        <a-dropdown>
          <template #overlay>
            <a-menu @click="handleLocaleClick">
              <a-menu-item key="zh">
                <img class="locale-image" :src="require('@/assets/zh_flag.png')" />
                简体中文
              </a-menu-item>
              <a-menu-item key="en">
                <img class="locale-image" :src="require('@/assets/en_flag.png')" />
                English
              </a-menu-item>
            </a-menu>
          </template>
          <a-button type="text" style="color: white; padding-left: 0px">
            <span style="margin-right: 10px">
              <img :src="localeImage" class="locale-image" />
            </span>
            {{ localeText }}
            <UpOutlined />
          </a-button>
        </a-dropdown>
      </a-col>
      <a-col flex="15px"></a-col>
    </a-row>
    <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
      <a-col flex="15px"></a-col>
      <a-col flex="1 1 auto">
        <span>
          <a href="https://beian.miit.gov.cn" class="icp" target="_blank">沪ICP备2021016007号-3</a>
        </span>
        <span style="color: white">&nbsp;| © 2021 因问科技 版权所有 保留一切权利</span>
      </a-col>
      <a-col flex="15px"></a-col>
    </a-row>
    <div style="height: 68px"></div>
  </div>
</template>

<script setup>
  import { footerConfig } from './footerConfig';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  const { footers } = footerConfig();
  import { UpOutlined } from '@ant-design/icons-vue';

  const router = useRouter();
  const clickFooter = (item) => {
    if (item.link) {
      window.open(item.link);
    } else if (item.page) {
      router.push({ name: item.page });
    }
  };

  const store = useStore();
  const i18n = useI18n();
  const localeImage = computed(() => {
    if (store.state.locale.language == 'en') {
      return require('@/assets/en_flag.png');
    } else {
      return require('@/assets/zh_flag.png');
    }
  });
  const localeText = computed(() => (store.state.locale.language == 'en' ? 'English' : '简体中文'));
  const handleLocaleClick = (e) => {
    store.commit('locale/SET_LANGUAGE', e);
    i18n.locale = e;
    window.location.reload();
  };
</script>

<style scoped>
  .footer {
    position: relative;
    background: #1e1e1e;
  }
  .footer-corner {
    position: absolute;
    left: 0;
    top: 0;
    width: 241px;
    height: 175px;
  }
  .icp {
    color: white;
    text-align: center;
  }
  .locale-image {
    width: 16px;
    height: 16px;
    margin-bottom: 4px;
  }
</style>
