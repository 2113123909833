<template>
  <div class="footer">
    <div style="height: 90px"></div>
    <a-row justify="center">
      <a-typography-title>联系我们</a-typography-title>
    </a-row>
    <div style="height: 70px"></div>
    <a-row justify="center">
      <a-col>
        <a-space direction="vertical" align="center" :size="20">
          <a-space>
            <img src="./images/email.png" style="width: 44px; height: 44px" />
            <a-typography-text>邮箱: hi@yuanben.org</a-typography-text>
          </a-space>
          <a-space>
            <img src="./images/location.png" style="width: 44px; height: 44px" />
            <a-typography-text>
              地址:陕西省西安市碑林区南二环西段69号西安创新设计中心2009
            </a-typography-text>
          </a-space>
        </a-space>
      </a-col>
    </a-row>
    <div style="height: 86px"></div>
    <a-divider style="background-color: #ffffff1a" />
    <a-row justify="center">
      <a-col>
        <a :href="icpLink" class="icp" target="_blank">沪ICP备2021016007号-4</a>
        <span>&nbsp;| © 2021 因问科技 版权所有 保留一切权利</span>
      </a-col>
    </a-row>
    <a-row justify="center" style="display: none">
      <a-col flex="1 0 10px"></a-col>
      <a-col flex="0 1 1180px">
        <a-row justify="space-between">
          <a-col>
            <a :href="icpLink" class="icp" target="_blank">沪ICP备2021016007号-4</a>
            <span style="color: white">&nbsp;| © 2021 因问科技 版权所有 保留一切权利</span>
          </a-col>
          <a-col>
            <a-dropdown>
              <template #overlay>
                <a-menu @click="handleLocaleClick">
                  <a-menu-item key="zh">
                    <img class="locale-image" :src="require('@/assets/zh_flag.png')" />
                    简体中文
                  </a-menu-item>
                  <a-menu-item key="en">
                    <img class="locale-image" :src="require('@/assets/en_flag.png')" />
                    English
                  </a-menu-item>
                </a-menu>
              </template>
              <a-button type="text" style="color: white">
                <span style="margin-right: 10px">
                  <img :src="localeImage" class="locale-image" />
                </span>
                {{ localeText }}
                <UpOutlined />
              </a-button>
            </a-dropdown>
          </a-col>
        </a-row>
      </a-col>
      <a-col flex="1 0 10px"></a-col>
    </a-row>
    <div style="height: 18px"></div>
  </div>
</template>

<script setup>
  import { UpOutlined } from '@ant-design/icons-vue';
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n';

  const store = useStore();
  let icpLink = 'https://beian.miit.gov.cn';
  const i18n = useI18n();
  const localeImage = computed(() => {
    if (store.state.locale.language == 'en') {
      return require('@/assets/en_flag.png');
    } else {
      return require('@/assets/zh_flag.png');
    }
  });
  const localeText = computed(() => (store.state.locale.language == 'en' ? 'English' : '简体中文'));
  const handleLocaleClick = (e) => {
    store.commit('locale/SET_LANGUAGE', e);
    i18n.locale = e;
    // window.location.reload();
    console.log('click', e);
  };
</script>

<style scoped>
  .footer {
    position: relative;
    background: #f7f7f7;
  }
  .icp {
    color: black;
  }
  .locale-image {
    width: 16px;
    height: 16px;
    margin-bottom: 4px;
  }
</style>
