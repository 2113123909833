<template>
  <div>
    <img ref="bgImageRef" class="bg-image" :src="loginBackgroundImg" />
    <div class="page-title">
      <img
        v-if="config.loginPageBackgroundImg === 'sunward-login-bg.png'"
        :src="require('./images/sunward-login-logo.png')"
      />
    </div>
    <div class="container">
      <div v-if="loginBigTitle" class="logo-big-title">{{ loginBigTitle }}</div>
      <img ref="logoImageRef" :src="logoImage" :style="{ width: logoWidth }" />
      <div style="height: 30px"></div>
      <a-tabs v-model:activeKey="activateName" @change="change">
        <a-tab-pane key="login" :tab="i18nText('密码登录')">
          <a-form
            ref="loginFormRef"
            :model="loginForm"
            :rules="loginRules"
            :wrapper-col="{ span: 24 }"
            @submit.prevent
            @finish="onLogin"
            @validate="handleLoginValidate"
          >
            <a-form-item name="login_id">
              <a-input
                v-model:value="loginForm.login_id"
                :placeholder="loginAccountPlaceHolder"
                autocomplete="on"
                name="login_id"
                size="large"
                @keyup.enter="handleLoginValidate"
              ></a-input>
            </a-form-item>
            <a-form-item name="password">
              <a-input-password
                v-model:value="loginForm.password"
                :placeholder="i18nText('请输入登录密码')"
                name="password"
                size="large"
                @keyup.enter="handleLoginValidate"
              ></a-input-password>
            </a-form-item>
            <a-row v-if="config.allowResetPassword" justify="end" style="margin: 4px 0px 16px 0px">
              <a-col>
                <a href="/reset-pass">{{ $t('忘记密码') }}</a>
              </a-col>
            </a-row>
            <a-form-item>
              <a-button
                id="login-button"
                type="primary"
                :loading="loading"
                :disabled="loginButtonDisabled"
                size="large"
                block
                html-type="submit"
              >
                {{ $t('登录') }}
              </a-button>
            </a-form-item>
            <div v-if="config.allowRegister" class="reset-password">
              <a-row justify="center">
                <a-col>
                  <a-space>
                    <a-typography-text>还没有账户？</a-typography-text>
                    <router-link :to="{ name: 'signUp' }">{{ $t('去注册') }}</router-link>
                  </a-space>
                </a-col>
              </a-row>
            </div>
          </a-form>
        </a-tab-pane>
        <a-tab-pane key="code-login" :tab="i18nText('验证码登录')">
          <a-form
            ref="codeLoginFormRef"
            :model="codeLoginForm"
            :rules="codeLoginRules"
            :wrapper-col="{ span: 24 }"
            @submit.prevent
            @finish="onCodeLogin"
            @validate="handleCodeLoginValidate"
          >
            <a-form-item name="login_id">
              <a-input
                v-model:value="codeLoginForm.login_id"
                :placeholder="loginAccountPlaceHolder"
                autocomplete="on"
                name="login_id"
                size="large"
                @keyup.enter="handleCodeLoginValidate"
              ></a-input>
            </a-form-item>
            <a-form-item class="login-form-item" name="validation_code">
              <ant-code-validate
                v-model:value="codeLoginForm.validation_code"
                :placeholder="i18nText('请输入手机验证码')"
                type="text"
                input-size="large"
                :validation-code-handler="requestValidationCode"
                :show-phonenumber="false"
                :phone-number="codeLoginForm.phonenumber"
                :input-height="40"
                :input-border-radius="4"
              />
            </a-form-item>
            <a-form-item>
              <a-button
                id="code-login-button"
                type="primary"
                :loading="loading"
                :disabled="codeLoginButtonDisabled"
                size="large"
                block
                html-type="submit"
              >
                {{ $t('登录') }}
              </a-button>
            </a-form-item>
            <div v-if="config.allowRegister" class="reset-password">
              <a-row justify="center">
                <a-col>
                  <a-space>
                    <a-typography-text>还没有账户？</a-typography-text>
                    <router-link :to="{ name: 'signUp' }">{{ $t('去注册') }}</router-link>
                  </a-space>
                </a-col>
              </a-row>
              <a-row justify="space-between" style="display: none">
                <a-col>
                  <a href="/reset-pass">{{ $t('忘记密码') }}</a>
                </a-col>
                <a-col>
                  <a-dropdown>
                    <template #overlay>
                      <a-menu @click="handleLocaleClick">
                        <a-menu-item key="zh">
                          <img class="locale-image" :src="require('@/assets/zh_flag.png')" />
                          简体中文
                        </a-menu-item>
                        <a-menu-item key="en">
                          <img class="locale-image" :src="require('@/assets/en_flag.png')" />
                          English
                        </a-menu-item>
                      </a-menu>
                    </template>
                    <a-button type="text">
                      <span style="margin-right: 10px">
                        <img :src="localeImage" class="locale-image" />
                      </span>
                      {{ localeText }}
                      <UpOutlined />
                    </a-button>
                  </a-dropdown>
                </a-col>
              </a-row>
            </div>
          </a-form>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
  import UserModel from '@/model/user';
  import config from '/config';
  export default {
    beforeRouteEnter(to, from, next) {
      UserModel.getMyUserInfo()
        .then(() => {
          if (from.name) {
            next({ name: from.name });
          } else {
            next({ name: config.defaultPage });
          }
        })
        .catch((error) => {
          console.log(error.toString());
          next();
        });
    },
  };
</script>

<script setup>
  import { computed, reactive, ref, onMounted } from 'vue';
  import UserAPI from '@/api/v1/users';
  import V2UserAPI from '@/api/v2/users';
  import ValidationCodeAPI from '@/api/v1/validationCode';
  import AntCodeValidate from '@/views/components/ant_code_validate.vue';
  import ErrorMessage from '@/model/errorMessage.js';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import { routeTabs } from '@/tool/routeTabs';
  import { i18nText } from '@/lang';
  import { useI18n } from 'vue-i18n';
  import { UpOutlined } from '@ant-design/icons-vue';
  import { Modal } from 'ant-design-vue';
  import CompanyModel from '@/model/company';

  const store = useStore();
  const router = useRouter();
  const logoImageRef = ref();
  const logoImage = require('./images/' + config.logoImageName);

  const bgImageRef = ref();
  const loginBackgroundImg = config.loginPageBackgroundImg
    ? require('./images/' + config.loginPageBackgroundImg)
    : require('@/assets/login-bg.png');

  const loginBigTitle = config.loginBigTitle;
  const logoWidth = ref('0px');
  const loading = ref(false);
  const { change, activeKey: activateName } = routeTabs('login');
  document.title = '登录' + ' | ' + config.webTitle;
  const loginFormRef = ref();
  const loginForm = reactive({
    login_id: '',
    password: '',
  });
  const loginErrorResponse = reactive({
    error: {},
  });
  const loginAccountPlaceHolder =
    config.allowPhoneLogin && config.allowUserNameLogin
      ? i18nText('请输入用户名或手机号')
      : config.allowUserNameLogin
      ? i18nText('请输入用户名')
      : i18nText('请输入手机号');

  let validateLoginPhoneNumber = async (_rule, value) => {
    if (config.allowUserNameLogin) {
      if (!value || value === '') {
        return Promise.reject(i18nText('用户名或密码错误'));
      }
      return Promise.resolve();
    } else if (config.allowPhoneLogin) {
      let reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
      if (!/^\d{11}$/.test(value)) {
        return Promise.reject(i18nText('手机号或密码错误'));
      } else if (loginForm.password.length < 8) {
        return Promise.reject(i18nText('手机号或密码错误'));
      } else if (!reg.test(loginForm.password)) {
        return Promise.reject(i18nText('手机号或密码错误'));
      } else {
        return Promise.resolve();
      }
    }
    return Promise.resolve();
  };

  let validateCode = async (_rule, value) => {
    let field = ErrorMessage.getMessageFieldNameFromError(loginErrorResponse.error);
    if (field == 'validation_code') {
      return Promise.resolve();
    }
    if (value === '') {
      return Promise.reject(i18nText('请输入手机验证码'));
    } else {
      return Promise.resolve();
    }
  };

  let loginCommonValidate = async () => {
    let field = ErrorMessage.getMessageFieldNameFromError(loginErrorResponse.error);
    if (field == '') {
      return Promise.resolve();
    }
    if (field == 'login_id' || field == 'password') {
      let message = ErrorMessage.getServerMessageFromError(loginErrorResponse.error);
      if (field == 'login_id' && message == 'operating_too_frequently') {
        return Promise.reject(i18nText('多次密码错误，请十分钟后再尝试'));
      }
      if (field == 'login_id' && message == 'employee_locked') {
        return Promise.reject(i18nText('你的账号已被冻结'));
      }
      return Promise.reject(
        i18nText(!config.allowUserNameLogin ? '手机号或密码错误' : '账户或密码错误'),
      );
    }
    return Promise.resolve();
  };

  const loginRules = {
    login_id: [
      { required: true, validator: validateLoginPhoneNumber, trigger: 'submit' },
      { required: true, validator: loginCommonValidate, trigger: 'submit' },
    ],
  };
  const loginButtonDisabled = computed(() => !loginForm.login_id || !loginForm.password);
  const handleLoginValidate = (...args) => {
    console.log(args);
    loginErrorResponse.error = {};
  };
  const route = useRoute();
  const onLogin = () => {
    loading.value = true;
    V2UserAPI.login(loginForm.login_id, loginForm.password)
      .then((response) => {
        localStorage.setItem('showConfidential', true);
        store.commit('user/SET_USER', response);
        localStorage.setItem('visibilitychange', 'changed');
        localStorage.removeItem('showConfidentialDialog');
        if (route.params.redirect) {
          // let redirect = decodeURIComponent(route.fullPath.split('redirect=')[1]);
          let redirect = route.params.redirect;
          location.href = redirect;
        } else {
          checkInviteCompanyAndJoinedCompany();
        }
      })
      .catch((error) => {
        let field = ErrorMessage.getMessageFieldNameFromError(error);
        let message = ErrorMessage.getServerMessageFromError(error);
        if (field == 'password' && message == 'invalid_password_format') {
          passWarning();
          return;
        }
        loginErrorResponse.error = error;
        loginFormRef.value.validate();
      })
      .finally(() => {
        loading.value = false;
      });
  };
  const checkInviteCompanyAndJoinedCompany = async () => {
    console.log('checkInviteCompanyAndJoinedCompany');
    try {
      let user = await UserAPI.getMyUserInfo();
      if (!user || user.card_no.length === 0) {
        router.push({ name: 'realAuth' });
        return;
      }
      let joinedCompanies = await CompanyModel.getJoinedCompanies();
      if (!joinedCompanies || joinedCompanies.length == 0) {
        router.push({ name: 'realAuth' });
        return;
      }
      let invitedCompany = await CompanyModel.checkHasInvitedCompany();
      if (invitedCompany.enterprise_name) {
        router.push({ name: 'invitedCompany' });
      } else {
        CompanyModel.getJoinedCompanies()
          .then((joinedCompanies) => {
            if (!config.multiCompany) {
              UserAPI.switchDisplayCompany(joinedCompanies[0].enterprise_id).then(() => {
                router.push({ name: config.defaultPage });
              });
              return;
            }
            if (joinedCompanies.length > 1) {
              router.push({ name: 'invitedCompany' });
            } else {
              router.push({ name: config.defaultPage });
            }
          })
          .catch((error) => {
            router.push({ name: config.defaultPage });
            console.log(error);
          });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const i18n = useI18n();
  const localeImage = computed(() => {
    if (store.state.locale.language == 'en') {
      return require('@/assets/en_flag.png');
    } else {
      return require('@/assets/zh_flag.png');
    }
  });
  const localeText = computed(() => (store.state.locale.language == 'en' ? 'English' : '简体中文'));
  const handleLocaleClick = (e) => {
    store.commit('locale/SET_LANGUAGE', e);
    i18n.locale = e;
    window.location.reload();
    console.log('click', e);
  };
  let validateCodeLoginPhoneNumber = async (_rule, value) => {
    if (!/^\d{11}$/.test(value)) {
      return Promise.reject(i18nText('手机号错误'));
    } else {
      return Promise.resolve();
    }
  };
  const passWarning = () => {
    Modal.warning({
      title: '密码强度提示',
      content: '您当前密码强度较弱，请先升级密码强度',
      okText: '升级',
      onOk() {
        goChangePass();
      },
    });
  };
  const goChangePass = () => {
    router.push({ name: 'resetPass' });
  };

  const codeLoginErrorResponse = reactive({
    error: {},
  });
  let codeLoginCommonValidate = async (_rule) => {
    let field = ErrorMessage.getMessageFieldNameFromError(codeLoginErrorResponse.error);
    if (field == '' || !field) {
      return Promise.resolve();
    }
    let message = ErrorMessage.getServerMessageFromError(codeLoginErrorResponse.error);
    if (field == 'login_id' && _rule.field === field) {
      if (field == 'login_id' && message == 'employee_locked') {
        return Promise.reject(i18nText('你的账号已被冻结'));
      }
      return Promise.reject(i18nText('手机号错误'));
    }
    if (field == 'validation_code' && _rule.field === field) {
      let localMessage = ErrorMessage.getMessageFromError(codeLoginErrorResponse.error);
      return Promise.reject(localMessage);
    }
    return Promise.resolve();
  };

  const codeLoginFormRef = ref();
  const codeLoginForm = reactive({
    login_id: '',
    validation_code: '',
  });
  const codeLoginRules = {
    login_id: [
      { required: true, validator: validateCodeLoginPhoneNumber, trigger: 'submit' },
      { required: true, validator: codeLoginCommonValidate, trigger: 'submit' },
    ],
    validation_code: [
      { required: true, validator: validateCode, trigger: 'submit' },
      { required: true, validator: codeLoginCommonValidate, trigger: 'submit' },
    ],
  };
  const codeLoginButtonDisabled = computed(
    () => !codeLoginForm.login_id || !codeLoginForm.validation_code,
  );
  const handleCodeLoginValidate = (...args) => {
    console.log(args);
    codeLoginErrorResponse.error = {};
  };
  const requestValidationCode = async () => {
    codeLoginErrorResponse.error = {};
    try {
      const values = await codeLoginFormRef.value.validateFields('login_id');
      console.log('codeLoginFormRef: ' + values);
      return ValidationCodeAPI.userValidationCode(14, codeLoginForm.login_id)
        .then(() => {
          Promise.resolve();
        })
        .catch((err) => {
          codeLoginErrorResponse.error = err;
          codeLoginFormRef.value.validateFields('validation_code');
          return Promise.reject(err);
        });
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
      return Promise.reject(errorInfo);
    }
  };
  const onCodeLogin = () => {
    loading.value = true;
    V2UserAPI.codeLogin(codeLoginForm.login_id, codeLoginForm.validation_code)
      .then((response) => {
        localStorage.setItem('showConfidential', true);
        store.commit('user/SET_USER', response);
        localStorage.setItem('visibilitychange', 'changed');
        localStorage.removeItem('showConfidentialDialog');
        if (route.params.redirect) {
          // let redirect = decodeURIComponent(route.fullPath.split('redirect=')[1]);
          let redirect = route.params.redirect;
          location.href = redirect;
        } else {
          checkInviteCompanyAndJoinedCompany();
        }
      })
      .catch((error) => {
        codeLoginErrorResponse.error = error;
        codeLoginFormRef.value.validate();
      })
      .finally(() => {
        loading.value = false;
      });
  };
  onMounted(() => {
    let image = new Image();
    image.src = logoImageRef.value.src;
    image.onload = () => {
      console.log(image.width);
      logoWidth.value = image.width / 2 + 'px';
    };
  });
</script>

<style lang="less" scoped>
  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }

  .page-title {
    padding: 22px 0 82px 46px;
    font-weight: 500;
    font-size: 20px;
    color: #262626;
  }

  .container {
    width: 440px;
    margin: auto;
    padding: 40px 32px 29px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  .welcome {
    font-weight: 700;
    font-size: 24px;
    color: #262626;
    padding-bottom: 30px;
  }

  :deep(.ant-form-item) {
    margin-bottom: 16px;
  }

  .reset-password {
    text-align: center;
    margin-top: 136px;
  }
  a {
    color: @primary-color;
  }
  .locale-image {
    width: 16px;
    height: 16px;
    margin-bottom: 4px;
  }

  .logo-big-title {
    font-size: 35px;
    margin-bottom: 8px;
  }
</style>
