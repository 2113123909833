import v1 from './v1';

export default {
  changeLoginPass(oldPass, newPass) {
    return v1.put('/users/password', { new_password: newPass, old_password: oldPass });
  },
  logout() {
    return v1.del('/users/tokens');
  },
  getMyUserInfo() {
    return v1.get('/users', { withCredentials: true });
  },
  updatePhoneNumber(new_phone_number, validation_code) {
    return v1.post('/vault/authorization', {
      new_phone_number: new_phone_number,
      validation_code: validation_code,
    });
  },
  updateUsername(name) {
    return v1.put('/users/name', {
      name,
    });
  },
  login(phonenumber, password) {
    return v1.post('/users', {
      login_id: phonenumber,
      password: password,
    });
  },
  register(phonenumber, password, validation_code, name) {
    return v1.post('/users/tokens', {
      phonenumber: phonenumber,
      name: name,
      password: password,
      validation_code: validation_code,
    });
  },
  resetLoginPass(phonenumber, password, validation_code) {
    return v1.put('/users/forgot-password', {
      phonenumber: phonenumber,
      password: password,
      validation_code: validation_code,
    });
  },
  ocrIDAvatar(imageId) {
    return v1.post('/users/ocr/identity-card', { image_id: imageId, side: 'face' });
  },
  ocrIDInsignia(imageId) {
    return v1.post('/users/ocr/identity-card', { image_id: imageId, side: 'back' });
  },
  switchDisplayCompany(enterpriseId) {
    return v1.put('/users/switch-enterprise', { enterprise_id: enterpriseId });
  },
};
