<script setup>
  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import ForceGraph3D from '3d-force-graph';
  import axios from 'axios';
  import { CSS2DRenderer, CSS2DObject } from 'three/addons/renderers/CSS2DRenderer.js';
  import { UnrealBloomPass } from 'three/addons/postprocessing/UnrealBloomPass.js';
  import * as THREE from 'three';

  const graph = ref(null);
  const Graph = ForceGraph3D({
    extraRenderers: [new CSS2DRenderer()],
  });
  const baseURL = 'https://ip-enterprise-api.yuanben.site';
  const selectedID = ref(0);

  const selectedFn = (selected) => {
    return (node) => {
      const nodeEl = document.createElement('div');
      if (node.id == selected || node.name == selected) {
        nodeEl.textContent = node.name;
        nodeEl.className = 'node-label';
        return new CSS2DObject(nodeEl);
      } else {
        return null;
      }
    };
  };

  const router = useRouter();
  const nodes = ['8db0a686-93a1-42ef-8c97-7361103d2ddf'];
  const lastNodeClick = ref(0);

  const enterNode = (node) => {
    if (nodes.includes(node.uuid)) {
      router.push({ name: 'assetsEnterprise', params: { uuid: node.uuid } });
    }
  };
  const focusNode = (node) => {
    const distance = 400;
    const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);

    const newPos =
      node.x || node.y || node.z
        ? { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }
        : { x: 0, y: 0, z: distance }; // special case if node is in (0,0,0)

    Graph.cameraPosition(
      newPos, // new position
      node, // lookAt ({ x, y, z })
      2000, // ms transition duration
    );
    Graph.nodeThreeObject(selectedFn(node.id));
  };

  onMounted(async () => {
    const res = await axios.get('v1/metric', { baseURL });
    const gData = { nodes: res.data.data, links: [] };
    Graph(graph.value)
      .graphData(gData)
      .nodeRelSize(1.5)
      .backgroundColor('#000A14')
      .nodeResolution(20)
      .nodeColor((node) => {
        if (node.activated) {
          return '#ff9900';
        } else {
          return '#DFFBFF';
        }
      })
      .onNodeClick((node) => {
        const t = new Date().getTime();
        const interval = t - lastNodeClick.value;
        selectedID.value = node.id;
        lastNodeClick.value = t;
        if (interval < 500) {
          // 双击
          enterNode(node);
        } else {
          // 单击
          focusNode(node);
        }
      })
      .onBackgroundClick(() => {
        Graph.nodeThreeObject(selectedFn(0));
      })
      .nodeThreeObjectExtend(true);
    const scene = Graph.scene();
    const backImg = require('@/assets/3dback.png');
    const loader = new THREE.TextureLoader();
    scene.background = loader.load(backImg);
    // new THREE.TextureLoader().load(backImg, function (texture) {
    //   texture.mapping = THREE.EquirectangularReflectionMapping;
    //   scene.background = texture;
    //   scene.environment = texture;
    // });
    const bloomPass = new UnrealBloomPass();
    bloomPass.strength = 2;
    bloomPass.radius = 1;
    bloomPass.threshold = 0.1;
    Graph.postProcessingComposer().addPass(bloomPass);
  });

  const input = ref('');
  const search = () => {
    const nodes = Graph.graphData().nodes;
    const node = nodes.find((node) => node.name.includes(input.value));
    if (node) {
      if (node.id == selectedID.value) {
        enterNode(node);
        return;
      }
      focusNode(node);
      selectedID.value = node.id;
    } else {
      window.alert('未找到相关企业');
    }
  };
  const activateNode = (node) => {
    focusNode(node);
    setTimeout(() => {
      node.activated = true;
      Graph.refresh();
    }, 2000);
  };
  const updateActivateNode = () => {
    axios.get('v1/enterprise/latest-activate', { baseURL }).then((res) => {
      if (res.data.id != 0) {
        const nodes = Graph.graphData().nodes;
        const node = nodes.find((node) => node.id == res.data.id);
        const time = new Date().getTime() / 1000;
        if (!node.activated) {
          activateNode(node);
        } else if (time - res.data.updated_at < 30) {
          focusNode(node);
        }
      }
    });
  };
  setInterval(updateActivateNode, 2000);
</script>

<template>
  <a-input
    v-model:value="input"
    class="search"
    placeholder="输入企业名称，按下回车键搜索"
    @keyup.enter="search"
  />
  <div id="graph" ref="graph" />
</template>

<style scoped>
  .search {
    position: absolute;
    width: 300px;
    height: 40px;
    top: 20px;
    right: 0;
    left: 0;
    margin: 0 auto;
    z-index: 999;
  }
  :deep(.scene-container) {
    margin: 0 auto;
  }
  :deep(.scene-tooltip) {
    font-size: 12px;
    padding: 1px 4px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
  }
  :deep(.node-label) {
    color: white;
    font-size: 12px;
    padding: 1px 4px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
  }
</style>
