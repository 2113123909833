<template>
  <div>
    <img ref="bgImageRef" class="bg-image" :src="loginBackgroundImg" />
    <div class="page-title"></div>
    <div class="container">
      <a-page-header :title="i18nText('用户注册')" @back="() => router.go(-1)" />
      <div style="height: 10px"></div>
      <div>
        <a-form
          ref="registerFormRef"
          :model="registerForm"
          :rules="registerRules"
          autocomplete="off"
          :wrapper-col="{ span: 24 }"
          @validate="handleRegisterValidate"
        >
          <a-form-item name="phonenumber">
            <a-input
              id="register-phone"
              v-model:value="registerForm.phonenumber"
              size="large"
              name="phonenumber"
              :placeholder="i18nText('请输入手机号')"
            ></a-input>
          </a-form-item>
          <a-form-item class="login-form-item" name="password">
            <a-input-password
              id="register-password"
              v-model:value="registerForm.password"
              size="large"
              name="password"
              :placeholder="i18nText('密码: 8位及以上含大小写字母、数字')"
            ></a-input-password>
          </a-form-item>
          <a-form-item name="repeatPassword">
            <a-input-password
              id="register-repeatpassword"
              v-model:value="registerForm.repeatPassword"
              size="large"
              name="repeatpassword"
              :placeholder="i18nText('确认密码: 8位及以上含大小写字母、数字')"
            ></a-input-password>
          </a-form-item>
          <a-form-item v-if="usernameAvailable" name="name">
            <a-input
              id="register-name"
              v-model:value="registerForm.name"
              size="large"
              name="name"
              :placeholder="i18nText('请输入姓名（可选）')"
            ></a-input>
          </a-form-item>
          <a-form-item class="login-form-item" name="validation_code">
            <ant-code-validate
              v-model:value="registerForm.validation_code"
              :placeholder="i18nText('请输入手机验证码')"
              type="text"
              input-size="large"
              :validation-code-handler="requestValidationCode"
              :show-phonenumber="false"
              :phone-number="registerForm.phonenumber"
              :input-height="40"
              :input-border-radius="4"
            />
          </a-form-item>
          <a-form-item class="agree-terms" name="noticed" required>
            <a-checkbox v-model:checked="registerForm.noticed">
              {{ $t('我已阅读并同意') }}
              <router-link :to="{ name: 'terms' }" target="_blank">
                {{ $t('用户协议') }}
              </router-link>
            </a-checkbox>
          </a-form-item>
          <a-form-item>
            <a-button
              type="primary"
              :loading="loading"
              block
              size="large"
              html-type="submit"
              :disabled="registerButtonDisabled"
              @click.prevent="onRegister"
            >
              {{ $t('注册用户') }}
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
  import UserModel from '@/model/user';
  import config from '/config';
  export default {
    beforeRouteEnter(to, from, next) {
      UserModel.getMyUserInfo()
        .then(() => {
          if (from.name) {
            next({ name: from.name });
          } else {
            next({ name: config.defaultPage });
          }
        })
        .catch((error) => {
          console.log(error.toString());
          next();
        });
    },
  };
</script>

<script setup>
  import { computed, reactive, ref } from 'vue';
  import UserAPI from '@/api/v1/users';
  import ValidationCodeAPI from '@/api/v1/validationCode';
  import AntCodeValidate from '@/views/components/ant_code_validate.vue';
  import ErrorMessage from '@/model/errorMessage.js';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { i18nText } from '@/lang';

  const store = useStore();
  const router = useRouter();
  const bgImageRef = ref();
  const loginBackgroundImg = config.loginPageBackgroundImg
    ? require('./images/' + config.loginPageBackgroundImg)
    : require('@/assets/login-bg.png');
  const loading = ref(false);
  document.title = '注册' + ' | ' + config.webTitle;

  let validateSignUpPassword = async (_rule, value) => {
    let reg;
    let error = '';
    if (config.strongPassword) {
      reg = new RegExp(/^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*)(?=.*[^A-Za-z0-9].*).{8,}$/);
      error = '要求：8位及以上包含特殊字符、大小写字母、数字';
    } else {
      reg = new RegExp(/^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{8,}$/);
      error = '要求：8位及以上包含大小写字母、数字';
    }
    if (!reg.test(value)) {
      return Promise.reject(i18nText(error));
    } else {
      return Promise.resolve();
    }
  };

  let validateCode = async (_rule, value) => {
    let field = ErrorMessage.getMessageFieldNameFromError(registerErrorResponse.error);
    if (field == 'validation_code') {
      return Promise.resolve();
    }
    if (value === '') {
      return Promise.reject(i18nText('请输入手机验证码'));
    } else {
      return Promise.resolve();
    }
  };
  const registerFormRef = ref();
  const registerForm = reactive({
    name: '',
    phonenumber: '',
    password: '',
    repeatPassword: '',
    noticed: false,
    validation_code: '',
  });

  const registerErrorResponse = reactive({
    error: {},
  });
  const usernameAvailable = computed(() => config.usernameAvailableWhenSignup);
  let validateRegisterPhoneNumber = async (_rule, value) => {
    if (!/^\d{11}$/.test(value)) {
      return Promise.reject(i18nText('手机号或密码错误'));
    } else {
      return Promise.resolve();
    }
  };
  let validateRepeatPassword = async (_rule, value) => {
    if (value !== registerForm.password) {
      return Promise.reject(i18nText('两次密码输入不一致'));
    } else {
      return Promise.resolve();
    }
  };

  let validateNotice = async (_rule, value) => {
    if (!value) {
      return Promise.reject(i18nText('请先阅读并同意用户协议'));
    } else {
      return Promise.resolve();
    }
  };
  let registerCommonValidate = async (_rule) => {
    let field = ErrorMessage.getMessageFieldNameFromError(registerErrorResponse.error);
    if (field == '') {
      return Promise.resolve();
    }
    let transMessage = '';
    if (
      registerErrorResponse.error.response &&
      registerErrorResponse.error.response.data &&
      registerErrorResponse.error.response.data.data &&
      registerErrorResponse.error.response.data.data.message
    ) {
      let server = registerErrorResponse.error.response.data.data.message;
      transMessage = ErrorMessage.getLocalMessage(server);
    }
    if (_rule.field == field) {
      return Promise.reject(transMessage);
    }
    return Promise.resolve();
  };

  const registerRules = {
    phonenumber: [
      { required: true, validator: validateRegisterPhoneNumber, trigger: 'submit' },
      { required: true, validator: registerCommonValidate, trigger: 'submit' },
    ],
    password: [
      { required: true, validator: validateSignUpPassword, trigger: 'submit' },
      { required: true, validator: registerCommonValidate, trigger: 'submit' },
    ],
    repeatPassword: [
      { required: true, validator: validateRepeatPassword, trigger: 'submit' },
      { required: true, validator: registerCommonValidate, trigger: 'submit' },
    ],
    validation_code: [
      { required: true, validator: validateCode, trigger: 'submit' },
      { required: true, validator: registerCommonValidate, trigger: 'submit' },
    ],
    noticed: [{ required: true, validator: validateNotice, trigger: 'submit' }],
  };
  const registerButtonDisabled = computed(
    () =>
      !(
        registerForm.phonenumber &&
        registerForm.password &&
        registerForm.repeatPassword &&
        registerForm.validation_code
      ),
  );
  const handleRegisterValidate = (...args) => {
    console.log(args);
  };

  const requestValidationCode = async () => {
    registerErrorResponse.error = {};
    try {
      const values = await registerFormRef.value.validateFields('phonenumber');
      console.log('registerFormRef: ' + values);
      return ValidationCodeAPI.userValidationCode(1, registerForm.phonenumber)
        .then(() => {
          Promise.resolve();
        })
        .catch((err) => {
          registerErrorResponse.error = err;
          registerFormRef.value.validateFields('validation_code');
          return Promise.reject(err);
        });
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
      return Promise.reject(errorInfo);
    }
  };

  const onRegister = () => {
    registerErrorResponse.error = {};
    registerFormRef.value.validate().then(() => {
      loading.value = true;
      UserAPI.register(
        registerForm.phonenumber,
        registerForm.password,
        registerForm.validation_code,
        registerForm.name,
      )
        .then((response) => {
          store.commit('user/SET_USER', response);
          router.push({ name: 'vault' });
          registerFormRef.value.resetFields();
        })
        .catch((error) => {
          registerErrorResponse.error = error;
          registerFormRef.value.validate();
        })
        .finally(() => {
          loading.value = false;
        });
    });
  };
</script>

<style lang="less" scoped>
  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }

  .page-title {
    padding: 22px 0 82px 46px;
    font-weight: 500;
    font-size: 20px;
    color: #262626;
  }

  .container {
    width: 440px;
    margin: auto;
    padding: 20px 32px 29px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  .welcome {
    font-weight: 700;
    font-size: 24px;
    color: #262626;
    padding-bottom: 30px;
  }

  :deep(.ant-form-item) {
    margin-bottom: 16px;
  }

  .reset-password {
    text-align: center;
    margin-top: 136px;
  }
  a {
    color: @primary-color;
  }
  .locale-image {
    width: 16px;
    height: 16px;
    margin-bottom: 4px;
  }

  .logo-big-title {
    font-size: 35px;
    margin-bottom: 8px;
  }
</style>
