import UserAPI from '@/api/v1/users';
import CompanyAPI from '@/api/v1/company';
import store from '@/store';
import config from '/config';
import V2CompanyAPI from '@/api/v2/company';

export default {
  getMyUserInfo() {
    return UserAPI.getMyUserInfo()
      .then((response) => {
        store.commit('user/SET_USER', response);
        return response;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  updateUserPhone(newPhoneNum, validation_code) {
    return UserAPI.updatePhoneNumber(newPhoneNum, validation_code).then(() => {
      return this.getMyUserInfo();
    });
  },
  updateUserName(name) {
    return UserAPI.updateUsername(name).then(() => {
      return this.getMyUserInfo();
    });
  },
  getMyUserAndCompanyInfo() {
    let api = CompanyAPI;
    if (config.realAuthNeedOCR) {
      api = CompanyAPI;
    } else {
      api = V2CompanyAPI;
    }
    return Promise.all([UserAPI.getMyUserInfo(), api.getCompany()]).then((infos) => {
      let user = infos[0];
      let company = infos[1];
      store.commit('user/SET_USER', user);
      store.commit('company/SET_COMPANY', company);
      return infos;
    });
  },
};
