import v2 from './v2';

export default {
  getCompany() {
    return v2.get('/enterprises');
  },
  addMember(name, phone, role, password) {
    return v2.post(
      '/users/members',
      {
        name: name,
        password: password,
        role: role,
        phone: phone,
      },
      { timeout: 10 * 1000 },
    );
  },
  addMultiMembers(members) {
    return v2.post('/users/invitation/members', {
      members: members,
    });
  },
  getCompanyEmployeeList(params) {
    return v2.get('/enterprises/employees', { params });
  },
  getCompanyInviteList(params) {
    return v2.get('/enterprises/invitations', { params });
  },
  getCompanyInviteInfo() {
    return v2.get('/users/invitation/enterprise');
  },
  getJoinedCompanies() {
    return v2.get('/enterprises/join-list');
  },
  dealCompanyInvite(id, status) {
    return v2.put(`/users/invitation/${id}`, { status: status });
  },
  updateMember(id, params) {
    return v2.put(`/users/members/${id}`, params);
  },
  joinCompany(code) {
    return v2.post('/enterprises/employees', { invitation_code: code });
  },
  refreshCode() {
    return v2.put('/enterprises/verified-token');
  },
  recommitCompanyByTable(fileId, certificate) {
    return v2.put('/enterprises/agent', {
      apply_book_id: fileId,
      organization_code_certificate_id: certificate,
    });
  },
};
