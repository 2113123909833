<template>
  <a-space direction="vertical" style="width: 100%" align="center" :size="16">
    <div></div>
    <a-typography-title :level="4">
      {{ $t('企业邀请码是字母和数字组成的8位编码，可通过管理员获取') }}
    </a-typography-title>
    <div style="height: 10px"></div>
    <a-form
      ref="joinFormRef"
      :label-col="{ style: { width: '100px' } }"
      :model="joinForm"
      :rules="joinRules"
      style="width: 500px"
      autocomplete="off"
      @validate="handleValidate"
      @submit.prevent
    >
      <a-form-item class="form-item" :label="i18nText('邀请码')" name="invitation_code">
        <a-input
          v-model:value="joinForm.invitation_code"
          size="large"
          :placeholder="i18nText('请输入企业邀请码')"
          type="text"
          @keyup.enter="joinCompany"
        />
      </a-form-item>
      <a-form-item
        class="form-item-btn"
        label=" "
        :colon="false"
        :label-col="{ style: { width: '0px' } }"
      >
        <a-space size="middle">
          <a-button size="large" @click="backToCompanyOperation">{{ $t('返回上一步') }}</a-button>
          <a-button :loading="loading" type="primary" size="large" @click="joinCompany">
            {{ $t('确认提交') }}
          </a-button>
        </a-space>
      </a-form-item>
    </a-form>
  </a-space>
</template>

<script setup>
  import { reactive, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import CompanyAPI from '@/api/v2/company';
  import ErrorMessage from '@/model/errorMessage';
  import { i18nText } from '@/lang';

  const loading = ref(false);
  const joinForm = reactive({
    invitation_code: '',
  });

  let validateCompanyName = async (_rule, value) => {
    if (value === '') {
      return Promise.reject(i18nText('请输入企业邀请码'));
    } else {
      return Promise.resolve();
    }
  };
  const errorResponse = ref({});
  let errorResponseValidate = async () => {
    let field = ErrorMessage.getMessageFieldNameFromError(errorResponse.value);
    if (field) {
      let error = ErrorMessage.getServerMessageFromError(errorResponse.value);
      let show = i18nText('网络异常,请稍后重试');
      if (error == 'invitation_code_invalid') {
        show = i18nText('企业邀请码错误，请重新输入');
      }
      return Promise.reject(show);
    }
    return Promise.resolve();
  };
  const joinRules = {
    invitation_code: [
      { required: true, trigger: 'submit', validator: validateCompanyName },
      { required: true, trigger: 'submit', validator: errorResponseValidate },
    ],
  };

  const joinFormRef = ref(null);
  const router = useRouter();
  const handleValidate = (...args) => {
    errorResponse.value = {};
    console.log(args);
  };

  const store = useStore();
  const joinCompany = () => {
    joinFormRef.value.validate().then(() => {
      loading.value = true;
      CompanyAPI.joinCompany(joinForm.invitation_code)
        .then((response) => {
          store.commit('company/JOIN_COMPANY', response);
          router.push({ name: 'company' });
        })
        .catch((error) => {
          errorResponse.value = error;
          joinFormRef.value.validate();
        })
        .finally(() => {
          loading.value = false;
        });
    });
  };

  const emit = defineEmits(['backToCompanyOperation']);
  const backToCompanyOperation = () => {
    emit('backToCompanyOperation');
  };
</script>

<style scoped>
  .form-item-btn {
    text-align: center;
  }
  :deep(.ant-form-item-required) {
    margin-top: 4px;
    font-size: 18px;
  }
</style>
