<template>
  <div class="front-page">
    <div class="header-blue-bg">
      <mobile-navbar></mobile-navbar>
      <div style="height: 44px"></div>
      <a-row justify="center">
        <a-col>
          <a-space direction="vertical" align="center">
            <a-space direction="vertical" :size="4">
              <a-typography-title style="font-size: 32px; color: white; text-align: center">
                {{ mobileMainTitleHead }}
              </a-typography-title>
              <a-typography-title style="font-size: 32px; color: white; text-align: center">
                {{ mobileMainTitleTail }}
              </a-typography-title>
            </a-space>
            <a-typography-text style="color: white; text-align: center; font-size: 20px">
              {{ subTitle }}
            </a-typography-text>
          </a-space>
        </a-col>
      </a-row>
      <div style="height: 22px"></div>
    </div>
    <div class="image-container">
      <div class="blue-bg"></div>
      <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
        <a-col flex="6px"></a-col>
        <a-col flex="0 1 auto">
          <img
            src="@/views/index/front-xian/front/images/mobile_title_pic.png"
            style="width: 100%; object-fit: cover"
          />
        </a-col>
        <a-col flex="6px"></a-col>
      </a-row>
    </div>
    <div style="height: 28px"></div>
    <a-row justify="center">
      <img src="@/views/index/front/images/xiushi_3.png" style="width: 60px; height: 13px" />
    </a-row>
    <div style="height: 40px"></div>
    <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
      <a-col flex="12px"></a-col>
      <a-col flex="1 1 auto">
        <a-space direction="vertical" :size="16" style="width: 100%">
          <a-card
            v-for="(item, index) in advantages"
            :key="index"
            style="width: 100%; background: #f5f4f5; border-radius: 32px"
          >
            <a-space direction="vertical" style="width: 100%" :size="4">
              <a-row justify="start" align="middle" :gutter="10">
                <a-col>
                  <img :src="item.image" style="width: 66px; height: 66px" />
                </a-col>
                <a-col>
                  <a-typography-title :level="4">{{ item.title }}</a-typography-title>
                </a-col>
              </a-row>
              <a-typography-text style="font-size: 18px; text-align: center">
                {{ item.description }}
              </a-typography-text>
              <div style="height: 26px"></div>
            </a-space>
          </a-card>
        </a-space>
      </a-col>
      <a-col flex="12px"></a-col>
    </a-row>
    <div style="height: 46px"></div>
    <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
      <a-col flex="12px"></a-col>
      <a-col flex="0 1 auto">
        <a-row>
          <a-col>
            <a-space direction="vertical" :size="12" align="center">
              <a-typography-title style="font-size: 32px">数字资产</a-typography-title>
              <a-space direction="vertical" :size="0">
                <a-typography-text
                  v-for="(text, index) in assetTexts"
                  :key="index"
                  style="font-size: 18px"
                >
                  {{ text }}
                </a-typography-text>
              </a-space>
              <div style="height: 22px"></div>
              <img
                src="@/views/index/front/images/shuzizhishicaichan.png"
                style="width: 240px; object-fit: cover"
              />
            </a-space>
          </a-col>
        </a-row>
      </a-col>
      <a-col flex="12px"></a-col>
    </a-row>
    <div style="height: 100px"></div>
    <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
      <a-col flex="12px"></a-col>
      <a-col flex="0 1 auto">
        <a-row type="flex" style="background: #f1f7fe; border-radius: 24px; flex-wrap: nowrap">
          <a-col flex="28px"></a-col>
          <a-col flex="0 1 auto">
            <a-space direction="vertical" align="center" :size="14">
              <div style="height: 34px"></div>
              <a-space direction="vertical" align="center">
                <a-typography-title :level="3">{{ insurances[0].title }}</a-typography-title>
                <a-typography-text style="font-size: 20px">
                  {{ insurances[0].description }}
                </a-typography-text>
              </a-space>
              <div style="height: 8px"></div>
              <img
                :src="insurances[0].image"
                style="
                  width: 200px;
                  padding: 16px 12px;
                  background: white;
                  border-radius: 16px;
                  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.06);
                "
              />
              <div style="height: 12px"></div>
            </a-space>
          </a-col>
          <a-col flex="28px"></a-col>
        </a-row>
      </a-col>
      <a-col flex="12px"></a-col>
    </a-row>
    <div style="height: 16px"></div>
    <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
      <a-col flex="12px"></a-col>
      <a-col flex="0 1 auto">
        <a-row type="flex" style="background: #f8f7fe; border-radius: 24px; flex-wrap: nowrap">
          <a-col flex="28px"></a-col>
          <a-col flex="0 1 auto">
            <a-space direction="vertical" align="center" :size="14">
              <div style="height: 34px"></div>
              <a-space direction="vertical" align="center">
                <a-typography-title :level="3">{{ insurances[1].title }}</a-typography-title>
                <a-typography-text style="font-size: 20px">
                  {{ insurances[1].description }}
                </a-typography-text>
              </a-space>
              <div style="height: 8px"></div>
              <img
                :src="insurances[1].image"
                style="
                  width: 200px;
                  padding: 16px 12px;
                  background: white;
                  border-radius: 16px;
                  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.06);
                "
              />
              <div style="height: 12px"></div>
            </a-space>
          </a-col>
          <a-col flex="28px"></a-col>
        </a-row>
      </a-col>
      <a-col flex="12px"></a-col>
    </a-row>
    <div style="height: 56px"></div>
    <div>
      <a-space direction="vertical" style="width: 100%" :size="36">
        <a-row justify="space-around" align="middle">
          <a-col>
            <a-space direction="vertical" align="center" :size="0">
              <a-typography-title :level="1">哪些财产</a-typography-title>
              <a-typography-title :level="1">需要被保护?</a-typography-title>
            </a-space>
          </a-col>
        </a-row>
        <a-row type="flex" style="flex-wrap: nowrap">
          <a-col flex="16px"></a-col>
          <a-col flex="1 1 auto">
            <a-space direction="vertical" :size="26">
              <a-row
                v-for="(item, index) in needProtecteds"
                :key="index"
                justify="start"
                align="middle"
                style="flex-wrap: nowrap"
                :gutter="16"
              >
                <a-col>
                  <img :src="item.image" style="width: 124px" />
                </a-col>
                <a-col>
                  <a-space direction="vertical" :size="4">
                    <a-typography-title :level="4">{{ item.title }}</a-typography-title>
                    <a-typography-text style="font-size: 16px">
                      {{ item.description }}
                    </a-typography-text>
                  </a-space>
                </a-col>
              </a-row>
            </a-space>
          </a-col>
          <a-col flex="16px"></a-col>
        </a-row>
      </a-space>
    </div>
    <div style="height: 72px"></div>
    <a-space direction="vertical" style="width: 100%" :size="15">
      <a-row justify="center">
        <a-typography-title style="font-size: 32px">解决方案</a-typography-title>
      </a-row>
      <div style="height: 7px"></div>
      <a-row
        v-for="(item, index) in solutions"
        :key="index"
        type="flex"
        justify="space-between"
        style="flex-wrap: nowrap"
      >
        <a-col flex="12px"></a-col>
        <a-col flex="1 1 auto">
          <a-card
            style="border-radius: 24px; border: 2px solid #315aff; box-shadow: 0px 3px 0px #315aff"
          >
            <a-row
              justify="space-between"
              style="background: #f5f5f5; border-radius: 22px; flex-wrap: nowrap"
              align="middle"
            >
              <a-col>
                <a-row type="flex" style="flex-wrap: nowrap">
                  <a-col flex="16px"></a-col>
                  <a-col flex="0 1 auto">
                    <a-space direction="vertical">
                      <a-typography-title :level="4">{{ item.title }}</a-typography-title>
                      <a-row style="height: 54px">
                        <a-typography-text style="font-size: 16px">
                          {{ item.description }}
                        </a-typography-text>
                      </a-row>
                    </a-space>
                  </a-col>
                  <a-col flex="16px"></a-col>
                </a-row>
              </a-col>
              <a-col>
                <img :src="item.image" style="width: 150px" />
              </a-col>
            </a-row>
          </a-card>
          <div style="height: 4px"></div>
        </a-col>
        <a-col flex="12px"></a-col>
      </a-row>
    </a-space>
    <div style="height: 48px"></div>
    <a-row justify="center">
      <img src="./images/xiushi_1.png" style="width: 170px; height: 75px" />
    </a-row>
    <div style="height: 76px"></div>
    <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
      <a-col flex="15px"></a-col>
      <a-col flex="0 1 auto">
        <a-row>
          <a-space direction="vertical" style="width: 100%" align="center">
            <a-row justify="center" class="bottom-card">
              <a-col flex="auto">
                <img src="./images/foundation.png" class="foundation-png" />
              </a-col>
            </a-row>
            <a-row>
              <a-col flex="28px"></a-col>
              <a-col flex="auto">
                <div style="position: relative">
                  <a-space style="width: 100%">
                    <img class="foundation-bg" src="./images/mobile_foundation_bg.png" />
                  </a-space>
                  <div class="sevice-text-container">
                    <a-space direction="vertical" :size="12">
                      <div style="height: 24px"></div>
                      <img src="./images/yinhao.png" style="width: 60px; height: 36px" />
                      <div style="height: 12px"></div>
                      <a-typography-title :level="3" style="color: white">
                        {{ foundationServices.title }}
                      </a-typography-title>
                      <a-typography-text style="color: white; font-size: 16px">
                        {{ foundationServices.descriptions }}
                      </a-typography-text>
                      <div style="height: 4px"></div>
                      <a-space
                        v-for="(item, index) in foundationServices.items"
                        :key="index"
                        :size="12"
                        align="center"
                      >
                        <img :src="item.image" style="width: 32px; height: 32px" />
                        <a-typography-text style="color: white; font-size: 16px">
                          {{ item.description }}
                        </a-typography-text>
                      </a-space>
                    </a-space>
                  </div>
                  <div class="arrow-decration">
                    <img
                      src="./images/mobile_foundation_arrow.png"
                      style="width: 46px; height: 49px"
                    />
                  </div>
                </div>
              </a-col>
              <a-col flex="28px"></a-col>
            </a-row>
          </a-space>
        </a-row>
      </a-col>
      <a-col flex="15px"></a-col>
    </a-row>
    <div style="height: 32px"></div>
    <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
      <a-col flex="15px"></a-col>
      <a-col flex="0 1 auto">
        <a-row>
          <a-space direction="vertical" style="width: 100%" align="center">
            <a-row justify="center" class="bottom-card">
              <a-col flex="auto">
                <img src="./images/mask_group.png" class="mask_group" />
              </a-col>
            </a-row>
            <a-row style="flex-wrap: nowrap">
              <a-col flex="28px"></a-col>
              <a-col flex="auto">
                <div style="position: relative">
                  <div style="width: 100%">
                    <a-row type="flex" class="left-color-bg">
                      <a-col flex="40px"></a-col>
                      <a-col flex="auto">
                        <a-space direction="vertical" :size="12">
                          <div style="height: 20px"></div>
                          <a-typography-title :level="4">
                            {{ bottomTexts[0].title }}
                          </a-typography-title>
                          <a-typography-text>
                            {{ bottomTexts[0].text }}
                          </a-typography-text>
                          <div style="height: 20px"></div>
                        </a-space>
                      </a-col>
                      <a-col flex="40px"></a-col>
                    </a-row>
                  </div>
                </div>
              </a-col>
              <a-col flex="28px"></a-col>
            </a-row>
          </a-space>
        </a-row>
      </a-col>
      <a-col flex="15px"></a-col>
    </a-row>
    <div style="height: 32px"></div>
    <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
      <a-col flex="15px"></a-col>
      <a-col flex="0 1 auto">
        <a-row>
          <a-space direction="vertical" style="width: 100%" align="center">
            <a-row justify="center" class="bottom-card">
              <a-col flex="auto">
                <img src="./images/mask_group_1.png" class="mask_group" />
              </a-col>
            </a-row>
            <a-row style="flex-wrap: nowrap">
              <a-col flex="28px"></a-col>
              <a-col flex="auto">
                <div style="position: relative">
                  <div style="width: 100%">
                    <a-row type="flex" class="right-color-bg">
                      <a-col flex="40px"></a-col>
                      <a-col flex="auto">
                        <a-space direction="vertical" :size="12">
                          <div style="height: 20px"></div>
                          <a-typography-title :level="4" style="color: white">
                            {{ bottomTexts[1].title }}
                          </a-typography-title>
                          <a-typography-text style="color: white">
                            {{ bottomTexts[1].text }}
                          </a-typography-text>
                          <div style="height: 20px"></div>
                        </a-space>
                      </a-col>
                      <a-col flex="40px"></a-col>
                    </a-row>
                  </div>
                </div>
              </a-col>
              <a-col flex="28px"></a-col>
            </a-row>
          </a-space>
        </a-row>
      </a-col>
      <a-col flex="15px"></a-col>
    </a-row>
    <div style="height: 80px"></div>
    <front-footer></front-footer>
  </div>
</template>

<script setup>
  // import { useRouter } from 'vue-router';
  import MobileNavbar from '@/views/index/front-navbar/mobile';
  import frontFooter from '@/views/index/front-xian/front-footer/mobile';
  import { frontConfig } from './frontConfig';
  const {
    mobileMainTitleHead,
    mobileMainTitleTail,
    subTitle,
    advantages,
    solutions,
    insurances,
    assetTexts,
    needProtecteds,
    bottomTexts,
    foundationServices,
  } = frontConfig();
</script>

<style scoped>
  .front-page {
    position: relative;
    overflow: hidden;
  }
  .header-blue-bg {
    background: linear-gradient(180deg, #4e45f4 0%, #4b58f3 100%);
  }
  .image-container {
    position: relative;
  }
  .blue-bg {
    background: linear-gradient(180deg, #4b58f3 0%, #476ff1 100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(100vw * 0.45);
    z-index: -1;
  }
  .sevice-text-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
  .arrow-decration {
    position: absolute;
    top: -24px;
    bottom: 0;
    left: 0px;
    right: 0;
    display: flex;
    justify-content: center;
  }
  .foundation-bg {
    width: 315px;
    object-fit: fill;
  }
  .mask_group,
  .foundation-png {
    width: calc(100vw - 30px - 20px);
    object-fit: fill;
  }
  .left-color-bg {
    width: 100%;
    flex-grow: 0;
    background: linear-gradient(180deg, #fccc0a 0%, #f2c349 100%);
    flex-wrap: nowrap;
    border-radius: 18px;
  }
  .right-color-bg {
    flex-grow: 0;
    background: linear-gradient(180deg, #4c4cf3 0%, #476df3 100%);
    flex-wrap: nowrap;
    border-radius: 18px;
  }
  .bottom-card {
    height: calc((100vw - 30px) * 0.53);
    width: 100%;
  }
</style>
