import CompanyAPI from '@/api/v1/company';
import V2CompanyAPI from '@/api/v2/company';
import store from '@/store';
import config from '/config';

export default {
  getCurrentCompanyInfo() {
    let api = CompanyAPI;
    if (config.realAuthNeedOCR) {
      api = CompanyAPI;
    } else {
      api = V2CompanyAPI;
    }
    return api
      .getCompany()
      .then((response) => {
        store.commit('company/SET_COMPANY', response);
        return response;
      })
      .catch((error) => {
        Promise.reject(error);
      });
  },
  checkHasInvitedCompany() {
    return V2CompanyAPI.getCompanyInviteInfo()
      .then((response) => {
        store.commit('company/SET_COMPANY', response);
        return response;
      })
      .catch((error) => {
        Promise.reject(error);
      });
  },
  getJoinedCompanies() {
    return V2CompanyAPI.getJoinedCompanies()
      .then((res) => {
        const companies = res.enterprises;
        store.commit('company/SET_COMPANIES', companies);
        return companies;
      })
      .catch((error) => {
        Promise.reject(error);
      });
  },
};
