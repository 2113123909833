<template>
  <a-layout>
    <a-layout-header>
      <navbar
        :show-login="false"
        :setting-visible="false"
        :show-search="false"
        :show-company="false"
      />
    </a-layout-header>
    <a-layout-content>
      <a-row align="center">
        <div class="auth-container">
          <div class="step-header">
            <a-steps v-model:current="authStep" type="navigation" :style="stepStyle">
              <a-step :status="stepStatus(0)" :title="i18nText('实名认证')" disabled />
              <a-step :status="stepStatus(1)" :title="i18nText('加入/创建企业')" disabled />
            </a-steps>
          </div>
          <div v-if="authStep == 0">
            <id-card @finishRealAuth="finishRealAuth"></id-card>
          </div>
          <div v-else class="second-step company-auth">
            <div v-if="wasInvitedJoin && inviteCompanyInfo.enterprise_name" class="company-select">
              <a-space
                class="outer-space"
                direction="vertical"
                :size="16"
                align="center"
                style="width: 100%"
              >
                <div></div>
                <a-typography-title :level="4">
                  {{ $t('以下企业邀请你加入') }}
                </a-typography-title>
                <div style="height: 10px"></div>
                <a-space direction="vertical" style="width: 100%">
                  <a-card>
                    <a-row type="flex" align="middle" :gutter="16">
                      <a-col flex="58px">
                        <a-avatar shape="square" size="large" class="avatar-back">
                          {{ inviteCompanyInfo.enterprise_name.slice(0, 1) }}
                        </a-avatar>
                      </a-col>
                      <a-col flex="auto">
                        <a-space direction="vertical">
                          <a-typography-text>
                            {{ inviteCompanyInfo.enterprise_name }}
                          </a-typography-text>
                          <a-typography-text type="secondary">
                            {{ inviteCompanyInfo.inviter }}
                          </a-typography-text>
                        </a-space>
                      </a-col>
                    </a-row>
                  </a-card>
                </a-space>
                <div style="height: 40px"></div>
                <a-button type="primary" :loading="dealInviteLoading" @click="joinInvitedCompany">
                  {{ $t('加入该企业') }}
                </a-button>
                <a-button type="text" danger :loading="dealInviteLoading" @click="refuseJoin">
                  {{ $t('拒绝加入') }}
                </a-button>
              </a-space>
            </div>
            <div v-else-if="showCompanyOption" class="company-select">
              <a-space direction="vertical" align="center" :size="16">
                <div></div>
                <a-typography-title :level="4">{{ $t('请选择以下操作') }}</a-typography-title>
                <div style="height: 10px"></div>
                <div class="operation-container">
                  <a-space
                    class="company-operation join-company"
                    direction="vertical"
                    @click="goJoin"
                  >
                    <block-outlined style="font-size: 34px; color: #2b55bb"></block-outlined>
                    <a-button class="button-bg">{{ $t('加入企业') }}</a-button>
                  </a-space>
                  <a-space
                    class="create-company company-operation"
                    direction="vertical"
                    @click="goCreate"
                  >
                    <home-outlined style="font-size: 34px; color: #2b55bb"></home-outlined>
                    <a-button class="button-bg">{{ $t('创建企业') }}</a-button>
                  </a-space>
                </div>
              </a-space>
            </div>
            <license v-if="showCreate" @backToCompanyOperation="backToCompanyOperation"></license>
            <join-company
              v-if="showJoin"
              @backToCompanyOperation="backToCompanyOperation"
            ></join-company>
          </div>
        </div>
      </a-row>
    </a-layout-content>
  </a-layout>
</template>

<script setup>
  import { onMounted } from 'vue';
  import navbar from '@/views/navbar';
  import IdCard from '@/views/index/real-auth/id-auth/components/id-card';
  import license from '@/views/index/real-auth/ocr-auth/components/license';
  import JoinCompany from '@/views/index/real-auth/id-auth/components/join-company';
  import { computed, ref } from 'vue';
  import { HomeOutlined, BlockOutlined } from '@ant-design/icons-vue';
  import { useStore } from 'vuex';
  import { i18nText } from '@/lang';
  import { useRouter } from 'vue-router';
  import UserModel from '@/model/user';
  import V2CompanyAPI from '@/api/v2/company';
  import { Modal } from 'ant-design-vue';
  // import UserAPI from '@/api/v1/users';

  const authStep = ref(0);
  const showCompanyOption = ref(true);
  const showCreate = ref(false);
  const showJoin = ref(false);
  const store = useStore();
  const wasInvitedJoin = ref(true);
  const userCardNum = computed(() => store.state.user.card_no);
  const finishRealAuth = () => {
    authStep.value = 1;
  };
  const stepStyle = {
    marginBottom: '60px',
    boxShadow: '0px -1px 0 0 #e8e8e8 inset',
  };
  const goCreate = () => {
    showCompanyOption.value = false;
    showCreate.value = true;
  };
  const goJoin = () => {
    showCompanyOption.value = false;
    showJoin.value = true;
  };
  const backToCompanyOperation = () => {
    showCompanyOption.value = true;
    showJoin.value = false;
    showCreate.value = false;
  };
  const stepStatus = (index) => {
    if (index > authStep.value) {
      return 'wait';
    } else if (index == authStep.value) {
      return 'process';
    } else {
      return 'finish';
    }
  };
  const getUserInfo = async () => {
    try {
      await UserModel.getMyUserInfo();
    } catch (e) {
      console.log(e);
    }
    if (userCardNum.value) {
      authStep.value = 1;
    }
  };
  const inviteCompanyInfo = ref({});
  const getInviteCompanyInfo = async () => {
    console.log('getInviteCompanyInfo');
    try {
      let res = await V2CompanyAPI.getCompanyInviteInfo();
      inviteCompanyInfo.value = res;
    } catch (e) {
      console.log(e);
    }
  };
  const router = useRouter();
  const dealInviteLoading = ref(false);
  const joinInvitedCompany = async () => {
    try {
      dealInviteLoading.value = true;
      await V2CompanyAPI.dealCompanyInvite(inviteCompanyInfo.value.invite_enterprise_id, 2);
      router.push({ name: 'company' });
    } catch (e) {
      console.log(e);
    }
    dealInviteLoading.value = false;
  };
  const refuseJoin = async () => {
    Modal.confirm({
      title: '你已选择拒绝加入该企业, 拒绝后将无法加入.若需重新加入,请联系管理员再次邀请',
      okText: '确认拒绝',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        return new Promise((resolve, reject) => {
          V2CompanyAPI.dealCompanyInvite(inviteCompanyInfo.value.invite_enterprise_id, 3)
            .then(() => {
              wasInvitedJoin.value = false;
              resolve();
            })
            .catch(() => {
              reject();
            });
        }).catch(() => console.log('Oops errors!'));
      },
    });
  };
  onMounted(() => {
    getUserInfo();
    getInviteCompanyInfo();
    // UserAPI.logout();
  });
</script>

<style scoped>
  .auth-container {
    width: 550px;
    height: auto;
    margin: 0 auto;
    background: white;
    margin-top: 50px;
  }

  .step-header {
    height: 120px;
    background: theme-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .company-select {
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .operation-container {
    display: flex;
  }

  .company-operation {
    display: flex;
    flex-direction: column;
    width: 246px;
    height: 153px;
    justify-content: center;
    align-items: center;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    color: @primary-color;
    margin: 0px 30px;
    background: #fafafa;
  }

  .company-operation:hover {
    border: 1px solid #2b55bb;
  }

  .button-bg {
    border: none;
    margin: 0px 30px;
    background: transparent;
    box-shadow: none;
  }
  :deep(.ant-card-body) {
    padding: 16px;
  }
  .avatar-back {
    color: white;
    background: linear-gradient(208.12deg, rgba(50, 87, 181, 0.8) 7.51%, #3257b5 91.29%);
  }
  .outer-space :nth-child(4) {
    width: 100%;
  }
</style>
