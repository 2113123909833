<template>
  <div>
    <img alt="login" class="bg-image" :src="require('@/assets/login-bg.png')" />
    <a-row justify="center">
      <a-space direction="vertical">
        <div style="height: 88px"></div>
        <a-card
          v-if="disclosureFile.file.disclosure_status > 4"
          :title="i18nText(config.webTitle)"
          style="width: 520px; height: 580px"
        >
          <a-space style="width: 100%" direction="vertical" align="center">
            <a-result
              status="404"
              title="链接已失效"
              sub-title="链接已失效，请联系对方重新生成"
            ></a-result>
          </a-space>
        </a-card>
        <a-card
          v-else-if="receiveStep == 1"
          :title="i18nText(config.webTitle)"
          style="width: 520px; height: 580px"
        >
          <a-space direction="vertical" align="center" style="width: 100%" :size="18">
            <div style="height: 34px"></div>
            <FileProtectOutlined style="color: #2b55bb; font-size: 38px" />
            <a-typography-text style="font-size: 16px">
              为了保证数据安全, 需要尾号{{ lastFourForPhonenumber }}的手机验证
            </a-typography-text>
            <a-form
              ref="codeFormRef"
              style="width: 360px"
              :model="codeForm"
              :rules="codeRules"
              :wrapper-col="{ span: 24 }"
              @submit.prevent
              @validate="handleValidate"
            >
              <a-form-item name="validation_code">
                <validation-code-input
                  ref="validation_code"
                  v-model:value="codeForm.validation_code"
                  :placeholder="i18nText('请输入手机验证码')"
                  name="validation_code"
                  input-size="large"
                  type="text"
                  autocomplete="on"
                  :validation-code-handler="requestValidationCode"
                  :show-phonenumber="false"
                  :phone-number="receivePhonenumber"
                  :input-height="40"
                  :input-border-radius="4"
                />
              </a-form-item>
              <a-form-item>
                <a-row justify="center">
                  <a-button
                    :disabled="verifyDisabled"
                    type="primary"
                    size="large"
                    @click="verifyValidateCode"
                  >
                    {{ $t('验证') }}
                  </a-button>
                </a-row>
              </a-form-item>
            </a-form>
          </a-space>
        </a-card>
        <a-card
          v-if="receiveStep == 2"
          :title="i18nText(config.webTitle)"
          style="width: 520px; height: 580px"
        >
          <a-space direction="vertical" align="center" style="width: 100%" :size="18">
            <div style="height: 34px"></div>
            <FileProtectOutlined style="color: #2b55bb; font-size: 38px" />
            <a-typography-title :level="4">文件外发协议</a-typography-title>
            <a-checkbox-group
              v-model:value="checked"
              name="checkboxgroup"
              :options="plainOptions"
              style="width: 300px"
            />
            <div style="height: 40px"></div>
            <a-typography-text type="secondary">{{ legalTipText }}</a-typography-text>
            <a-button
              type="primary"
              :disabled="receiveDisabled"
              :loading="loading"
              size="large"
              @click="confirmReceive"
            >
              确认接收
            </a-button>
            <a-typography-text
              class="reject-text"
              type="secondary"
              style="cursor: pointer"
              @click="rejectReceive"
            >
              拒绝承诺
            </a-typography-text>
          </a-space>
        </a-card>
        <a-card v-if="receiveStep == 3" style="width: 520px; height: 650px">
          <a-row type="flex" style="flex-wrap: nowrap">
            <a-col flex="60px"></a-col>
            <a-col flex="0 1 auto">
              <a-space direction="vertical" align="center" style="width: 100%" :size="18">
                <div style="height: 40px"></div>
                <img src="./images/image.png" style="width: 350px" />
                <a-typography-title :level="4">文件外发，不再担心泄密侵权</a-typography-title>
                <a-row>
                  <a-typography-text style="font-size: 16px">
                    {{ tipHeadText }}
                    <a-typography-link :href="homePage" target="_blank">
                      {{ config.webTitle }}
                    </a-typography-link>
                    {{ tipText }}
                  </a-typography-text>
                </a-row>
                <div style="height: 50px"></div>
                <a-typography-text type="secondary">正在下载文件...</a-typography-text>
              </a-space>
            </a-col>
            <a-col flex="60px"></a-col>
          </a-row>
        </a-card>
      </a-space>
    </a-row>
  </div>
</template>

<script setup>
  import { computed, reactive, ref } from 'vue';
  import { onBeforeRouteLeave, useRoute } from 'vue-router';
  import ValidationCodeAPI from '@/api/v1/validationCode';
  import ValidationCodeInput from '@/views/components/ant_code_validate.vue';
  import ErrorMessage from '@/model/errorMessage';
  import { i18nText } from '@/lang';
  import { FileProtectOutlined } from '@ant-design/icons-vue';
  import config from '/config';
  import DisclosureAPI from '@/api/v2/disclosure';
  import { onMounted } from 'vue';
  import { ApiPromise } from '@/api/api-promise';
  const route = useRoute();
  const receiveStep = ref(1);
  const codeFormRef = ref();
  const tipHeadText = '使用「';
  const tipText =
    '」生成文件外发链接，对文件传播路径固化存证。有效保护企业知识产权，促进企业创新发展。';
  const plainOptions = [
    '承诺对收到的文件进行严格保密',
    '承诺不向任何人透露收到的文件内容',
    '承诺不复现、重现收到的文件内容',
  ];
  const legalTipText = '文件一旦接收,您将承担对应的法律责任';
  const codeForm = reactive({
    validation_code: '',
  });
  const checked = ref([]);
  const disclosureFile = reactive({
    file: {},
  });
  const receivePhonenumber = computed(() => disclosureFile.file.received_phone);
  const lastFourForPhonenumber = computed(() => {
    if (receivePhonenumber.value) {
      return receivePhonenumber.value.substring(
        receivePhonenumber.value.length - 4,
        receivePhonenumber.value.length,
      );
    }
    return '';
  });
  const homePage = 'https://' + window.location.host;
  const verifyDisabled = computed(() => codeForm.validation_code.length != 6);
  const needCheckCodeEmpty = ref(true);
  let validateCode = async (_rule, value) => {
    if (!value && needCheckCodeEmpty.value) {
      return Promise.reject(i18nText('请输入手机验证码'));
    } else {
      return Promise.resolve();
    }
  };

  const errorResponse = reactive({
    error: {},
  });

  let errorResponseValidate = async (_rule) => {
    let field = ErrorMessage.getMessageFieldNameFromError(errorResponse.error);
    if (field == '') {
      return Promise.resolve();
    }
    let transMessage = ErrorMessage.getMessageFromError(errorResponse.error);
    if (_rule.field === field) {
      if (
        errorResponse.error.response.data &&
        errorResponse.error.response.data.data &&
        errorResponse.error.response.data.data.message
      ) {
        let server = errorResponse.error.response.data.data.message;
        transMessage = ErrorMessage.getLocalMessage(server);
      }
      return Promise.reject(transMessage);
    }
    return Promise.resolve();
  };

  const codeRules = {
    validation_code: [
      { required: true, trigger: 'submit', validator: validateCode },
      { required: true, trigger: 'submit', validator: errorResponseValidate },
    ],
  };

  const handleValidate = () => {
    errorResponse.error = {};
  };

  const loading = ref(false);
  const verifyValidateCode = async () => {
    await codeFormRef.value.validateFields();
    let fileId = route.params.disclosureId;
    if (config.apiContainBigint) {
      fileId = BigInt(route.params.disclosureId);
    }
    loading.value = true;
    DisclosureAPI.validateReceiver(codeForm.validation_code, fileId)
      .then(() => {
        receiveStep.value = 2;
      })
      .catch((err) => {
        errorResponse.error = err;
        codeFormRef.value.validateFields('validation_code');
      })
      .finally(() => {
        loading.value = false;
      });
  };
  const requestValidationCode = async () => {
    try {
      await codeFormRef.value.validateFields('phonenumber');
      return ValidationCodeAPI.userValidationCode(8, receivePhonenumber.value)
        .then(() => {
          Promise.resolve();
        })
        .catch((err) => {
          errorResponse.error = err;
          needCheckCodeEmpty.value = false;
          codeFormRef.value.validateFields('validation_code');
          needCheckCodeEmpty.value = true;
          return Promise.reject(err);
        });
    } catch (errorInfo) {
      return Promise.reject(errorInfo);
    }
  };
  const receiveDisabled = computed(() => {
    return !(checked.value[0] && checked.value[1] && checked.value[2]);
  });
  const rejectReceive = () => {
    receiveStep.value = 1;
  };

  const requestDownloadURL = () => {
    const fileId = route.params.disclosureId;
    DisclosureAPI.getDownloadURL(fileId)
      .then((res) => {
        if (res.file_link && res.file_link !== '') {
          loading.value = false;
          window.open(res.file_link, '_self', '');
          receiveStep.value = 3;
          stopLoop();
        }
        loading.value = false;
      })
      .catch(() => {});
  };

  let loop = null;

  const startLoop = () => {
    stopLoop();
    loop = setInterval(requestDownloadURL, 1000);
  };

  const stopLoop = () => {
    clearInterval(loop);
    loop = null;
  };

  const confirmReceive = async () => {
    loading.value = true;
    const fileId = route.params.disclosureId;
    try {
      await DisclosureAPI.acceptProtcol(fileId);
      startLoop();
    } catch (e) {
      loading.value = false;
      ApiPromise.defaultOnReject(e);
    }
  };

  const getDisclosureDetail = async () => {
    const fileId = route.params.disclosureId;
    let res = await DisclosureAPI.getDisclosureDetail(fileId);
    disclosureFile.file = res;
  };
  onMounted(() => {
    getDisclosureDetail();
  });
  onBeforeRouteLeave(() => {
    stopLoop();
  });
</script>

<style scoped>
  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }
  :deep(.ant-checkbox-wrapper) {
    font-size: 16px;
  }
  .reject-text:hover {
    color: #262626;
  }
</style>
