import { i18nText } from '@/lang';

export const errorMessages = [
  {
    server: 'integrals is not enough',
    local: i18nText('您的积分不足，请先充值'),
  },
  {
    server: 'integrals_is_not_enough',
    local: i18nText('您的积分不足，请先充值'),
  },
  {
    server: 'hash duplicate',
    local: i18nText('文件已存在, 请重新选择'),
  },
  {
    server: 'hash_duplicate',
    local: i18nText('文件已存在, 请重新选择'),
  },
  {
    server: 'vault login status expired',
    local: i18nText('保险箱金钥已过期'),
  },
  {
    server: 'vault_login_status_expired',
    local: i18nText('保险箱金钥已过期'),
  },
  {
    server: 'project_already_exist',
    local: i18nText('项目已存在，请修改项目名'),
  },
  {
    server: 'user_not_found',
    local: i18nText('用户不存在'),
  },
  {
    server: 'invalid_password',
    local: i18nText('密码不正确'),
  },
  {
    server: 'password_is_invalid',
    local: i18nText('密码不正确'),
  },
  {
    server: 'password in invalid',
    local: i18nText('原始密码错误'),
  },
  {
    server: 'password_in_invalid',
    local: i18nText('原始密码错误'),
  },
  {
    server: 'the operation is too frequent',
    local: i18nText('操作太频繁了，请稍后再试'),
  },
  {
    server: 'the_operation_is_too_frequent',
    local: i18nText('操作太频繁了，请稍后再试'),
  },
  {
    server: 'token is invalid',
    local: i18nText('token已过期，请重新输入邮箱'),
  },
  {
    server: 'token_is_invalid',
    local: i18nText('token已过期，请重新输入邮箱'),
  },
  {
    server: 'GetZkpProofRequest: record not found',
    local: i18nText('数字资产ID不正确'),
  },
  {
    server: 'GetZkpProofRequest: record_not_found',
    local: i18nText('数字资产ID不正确'),
  },
  {
    server: 'ZkpVerifyFile: record not found',
    local: i18nText('原始文件与证明文件不匹配'),
  },
  {
    server: 'ZkpVerifyFile: record_not_found',
    local: i18nText('原始文件与证明文件不匹配'),
  },
  {
    server: 'ZkpVerifyFile: file hash mismatch',
    local: i18nText('原始文件与证明文件不匹配'),
  },
  {
    server: 'ZkpVerifyFile: file_hash_mismatch',
    local: i18nText('原始文件与证明文件不匹配'),
  },
  {
    server: 'ZkpVerifyFile: no proof package file ',
    local: i18nText('证明文件不存在，可能已失效'),
  },
  {
    server: 'ZkpVerifyFile: no_proof_package_file',
    local: i18nText('证明文件不存在，可能已失效'),
  },
  {
    server: 'ZkpVerifyFile: gzip: invalid header',
    local: i18nText('证明文件格式错误'),
  },
  {
    server: 'ZkpVerifyFile: gzip: invalid_header',
    local: i18nText('证明文件格式错误'),
  },
  {
    server: 'identity info is invalid',
    local: i18nText('实名认证失败，请核对后重新输入'),
  },
  {
    server: 'identity_info_is_invalid',
    local: i18nText('实名认证失败，请核对后重新输入'),
  },
  {
    server: 'card_no',
    local: i18nText('实名认证失败，请核对后重新输入'),
  },
  {
    server: 'validation_error',
    local: i18nText('手机验证码错误，请核对后重新输入'),
  },
  {
    server: 'validation code mismatch',
    local: i18nText('手机验证码错误，请核对后重新输入'),
  },
  {
    server: 'validation_code_mismatch',
    local: i18nText('手机验证码错误，请核对后重新输入'),
  },
  {
    server: 'CreateFileUploadRequest: validation code mismatch',
    local: i18nText('手机验证码错误，请核对后重新输入'),
  },
  {
    server: 'CreateFileUploadRequest: validation_code_mismatch',
    local: i18nText('手机验证码错误，请核对后重新输入'),
  },
  {
    server: 'UpdateFile: validation code mismatch',
    local: i18nText('手机验证码错误，请核对后重新输入'),
  },
  {
    server: 'UpdateFile: validation_code_mismatch',
    local: i18nText('手机验证码错误，请核对后重新输入'),
  },
  {
    server: 'CreateAssetsFile: validation code mismatch',
    local: i18nText('手机验证码错误，请核对后重新输入'),
  },
  {
    server: 'CreateAssetsFile: validation_code_mismatch',
    local: i18nText('手机验证码错误，请核对后重新输入'),
  },
  {
    server: 'validation code expired',
    local: i18nText('验证码已过期，请重新输入'),
  },
  {
    server: 'validation_code_expired',
    local: i18nText('验证码已过期，请重新输入'),
  },
  {
    server: 'validation code filled',
    local: i18nText('验证码请求过于频繁，请一分钟后再试'),
  },
  {
    server: 'validation_code_filled',
    local: i18nText('验证码请求过于频繁，请一分钟后再试'),
  },
  {
    server: 'validation_code_is_invalid',
    local: i18nText('手机验证码错误，请核对后重新输入'),
  },
  {
    server: 'validation_code_does_not_generate',
    local: i18nText('验证码未发送，请先发送验证码'),
  },
  {
    server: 'validation code does not generate',
    local: i18nText('验证码未发送，请先发送验证码'),
  },
  {
    server: 'enterprise_already_join',
    local: i18nText('你已加入企业'),
  },
  {
    server: 'employee locked',
    local: i18nText('你的账号已被冻结'),
  },
  {
    server: 'employee_locked',
    local: i18nText('你的账号已被冻结'),
  },

  {
    server: 'identity info is invalid',
    local: i18nText('身份信息错误，请检查后重新输入'),
  },
  {
    server: 'identity_info_is_invalid',
    local: i18nText('身份信息错误，请检查后重新输入'),
  },
  {
    server: 'recognize identity card failed',
    local: i18nText('图像识别失败，可能是图片模糊导致'),
  },
  {
    server: 'recognize_identity_card_failed',
    local: i18nText('图像识别失败，可能是图片模糊导致'),
  },
  {
    server: 'identity verify info is invalid',
    local: i18nText('请上传与登录手机号个人信息一致的身份证照片！'),
  },
  {
    server: 'identity_verify_info_is_invalid',
    local: i18nText('请上传与登录手机号个人信息一致的身份证照片！'),
  },
  {
    server: 'the enterprise legal person does not conform to the business license',
    local: i18nText('企业法人与营业执照信息不符'),
  },
  {
    server: 'the_enterprise_legal_person_does_not_conform_to_the_business_license',
    local: i18nText('企业法人与营业执照信息不符'),
  },
  {
    server: 'not found enterprise license record',
    local: i18nText('营业执照未上传'),
  },
  {
    server: 'not_found_enterprise_license_record',
    local: i18nText('营业执照未上传'),
  },
  {
    server: 'identity card expired',
    local: i18nText('身份证已过期'),
  },
  {
    server: 'identity_card_expired',
    local: i18nText('身份证已过期'),
  },
  {
    server: 'recognize license failed',
    local: i18nText('图片识别失败，可能是图片模糊导致'),
  },
  {
    server: 'recognize_license_failed',
    local: i18nText('图片识别失败，可能是图片模糊导致'),
  },
  {
    server: 'password need to contain letters and numbers',
    local: i18nText('密码必须包含数字和字母'),
  },
  {
    server: 'password_need_to_contain_letters_and_numbers',
    local: i18nText('密码必须包含数字和字母'),
  },
  {
    server: 'recognize failed',
    local: i18nText('身份证识别失败，可能是图片模糊导致'),
  },
  {
    server: 'recognize_failed',
    local: i18nText('身份证识别失败，可能是图片模糊导致'),
  },
  {
    server: 'recognize id card back failed',
    local: i18nText('身份证国徽面照片识别失败,请重新拍摄并上传'),
  },
  {
    server: 'recognize_id_card_back_failed',
    local: i18nText('身份证国徽面照片识别失败,请重新拍摄并上传'),
  },
  {
    server: 'recognize id card face failed',
    local: i18nText('身份证人像面照片识别失败,请重新拍摄并上传'),
  },
  {
    server: 'recognize_id_card_face_failed',
    local: i18nText('身份证人像面照片识别失败,请重新拍摄并上传'),
  },
  {
    server: 'recognize license failed',
    local: i18nText('营业执照识别失败,请重新拍摄并上传'),
  },
  {
    server: 'recognize_license_failed',
    local: i18nText('营业执照识别失败,请重新拍摄并上传'),
  },
  {
    server: 'invalid verification code',
    local: i18nText('无效验证码'),
  },
  {
    server: 'invalid_verification_code',
    local: i18nText('无效验证码'),
  },
  {
    server: 'the front and back of identity card information is needed',
    local: i18nText('必须上传正面和反面'),
  },
  {
    server: 'the_front_and_back_of_identity_card_information_is_needed',
    local: i18nText('必须上传正面和反面'),
  },
  {
    server: 'the modification of identity information is invalid',
    local: i18nText('填写的身份信息不正确'),
  },
  {
    server: 'the_modification_of_identity_information_is_invalid',
    local: i18nText('填写的身份信息不正确'),
  },
  {
    server: 'the verification of identity information is invalid',
    local: i18nText('请上传与登录手机号个人信息一致的身份证照片！'),
  },
  {
    server: 'verification_of_identity_information_is_invalid',
    local: i18nText('请上传与登录手机号个人信息一致的身份证照片！'),
  },
  {
    server: 'enterprise already join',
    local: i18nText('您已加入企业'),
  },
  {
    server: 'the name of enterprise legal person does not conform to the business license',
    local: i18nText('法人信息与营业执照信息不符'),
  },
  {
    server: 'the_name_of_enterprise_legal_person_does_not_conform_to_the_business_license',
    local: i18nText('法人信息与营业执照信息不符'),
  },
  {
    server: 'the name of app already exist',
    local: i18nText('应用已存在'),
  },
  {
    server: 'the_name_of_app_already_exist',
    local: i18nText('应用已存在'),
  },
  {
    server: 'parser pdf file failed',
    local: i18nText('产权证明证书解析错误'),
  },
  {
    server: 'parser_pdf_file_failed',
    local: i18nText('产权证明证书解析错误'),
  },
  {
    server: 'file hashes is inconsistent',
    local: i18nText('产权证明验证失败，与原始内容不一致'),
  },
  {
    server: 'file_hashes_is_inconsistent',
    local: i18nText('产权证明验证失败，与原始内容不一致'),
  },
  {
    server: 'password is invalid',
    local: i18nText('密码不正确'),
  },
  {
    server: 'len',
    local: i18nText('不符合长度限制，请重新输入'),
  },
  {
    server: 'lt',
    local: i18nText('超出限制长度，请重新输入'),
  },
  {
    server: 'gt',
    local: i18nText('内容太短，请重新输入'),
  },
  {
    server: 'enterprise_already_exist',
    local: i18nText('该企业已在平台注册！'),
  },
  {
    server: 'enterprise_already_join',
    local: i18nText('您已加入企业'),
  },
  {
    server: 'user_already_exist',
    local: i18nText('用户已存在'),
  },
  {
    server: 'user_is_existed',
    local: i18nText('用户已存在'),
  },
  {
    server: 'enterprise_not_found',
    local: i18nText('企业不存在，可能正在审核中'),
  },
  {
    server: 'The real name information is empty',
    local: i18nText('用户未实名认证，请先完成实名认证'),
  },
  {
    server: 'verification_of_license_identity_information_is_invalid',
    local: i18nText('营业执照识别失败,请重新拍摄并上传'),
  },
  {
    server: 'role_name_already_exists',
    local: i18nText('已经提交过该角色，请勿重复提交'),
  },
];

export const UserLoginStatuExpired = 'user login status expired';

export default {
  getMessageTypes() {
    return errorMessages;
  },

  getLocalMessage(server) {
    let label = null;
    errorMessages.forEach((message) => {
      if (message.server === server) {
        label = message.local;
      }
    });
    return label;
  },
  getMessageFromError(error) {
    let showMessage = '网络异常请稍后重试'; //error.toString();
    try {
      let errorMessage =
        (error.response.data.data && error.response.data.data.message) ||
        error.response.data.message;
      let localMessage = this.getLocalMessage(errorMessage);
      if (localMessage) {
        showMessage = localMessage;
      }
    } catch (e) {
      console.log(e.toString());
    }
    return showMessage;
  },
  getServerMessageFromError(error) {
    let message = null;
    try {
      let errorMessage =
        (error.response.data && error.response.data.message) ||
        (error.response.data.data && error.response.data.data.message);
      if (errorMessage) {
        message = errorMessage;
      }
    } catch (e) {
      console.log(e.toString());
    }
    return message;
  },
  getMessageFieldNameFromError(error) {
    if (error == undefined || error == null) {
      return '';
    }
    let field = null;
    try {
      let errorField =
        error.response.data.field_name ||
        (error.response.data.data && error.response.data.data.field_name);
      if (errorField) {
        field = errorField;
      }
    } catch (e) {
      console.error(e.toString());
    }
    return field;
  },
};
