import { createRouter, createWebHistory } from 'vue-router';
import config from '/config';
// import { pluginLoaders } from "../pluginLoader/pluginLoaders";

const index = () => import(/*webpackMode: "eager" */ '@/views/index/index.vue');
const login = () => import(/*webpackMode: "eager" */ '@/views/index/login/index.vue');
const signUp = () => import(/*webpackMode: "eager" */ '@/views/index/sign-up/index.vue');
const inReview = () => import(/*webpackMode: "eager" */ '@/views/index/in-review/index.vue');
const resetPass = () => import(/* webpackMode: "eager" */ '@/views/index/reset-pass/index.vue');
const terms = () => import(/* webpackMode: "eager" */ '@/views/index/terms/index.vue');
const privacy = () => import(/* webpackMode: "eager" */ '@/views/index/privacy/index.vue');
const verifyFile = () => import(/* webpackMode: "eager" */ '@/views/index/verify-file/index.vue');
const empty = () => import(/* webpackMode: "eager" */ '@/views/index/empty/index.vue');
const bigScreen = () => import(/* webpackMode: "eager" */ '@/views/index/big-screen/index.vue');
const front = () => import(/* webpackMode: "eager" */ '@/views/index/front/index.vue');
const frontGovernment = () =>
  import(/* webpackMode: "eager" */ '@/views/index/front-government/index.vue');
const partner = () => import(/* webpackMode: "eager" */ '@/views/index/partner/index.vue');
const realAuth = () => import(/* webpackMode: "eager" */ '@/views/index/real-auth/index.vue');
const disclosureReceive = () =>
  import(/* webpackMode: "eager" */ '@/views/index/disclosure-receive/index.vue');
const assetsMap = () => import(/* webpackMode: "eager" */ '@/views/index/assets/map/index.vue');
const assetsEnterprise = () =>
  import(/* webpackMode: "eager" */ '@/views/index/assets/enterprise/index.vue');
const assetsActivate = () =>
  import(/* webpackMode: "eager" */ '@/views/index/assets/activate/index.vue');

const dashboard = () =>
  import(/* webpackChunkName: "dashboard", webpackPrefetch: true */ '@/views/dashboard/index.vue');
//vault
const vault = () =>
  import(
    /* webpackChunkName: "dashboard", webpackPrefetch: true */ '@/views/dashboard/content/vault/index.vue'
  );
const vaultLog = () =>
  import(
    /* webpackChunkName: "dashboard", webpackPrefetch: true */ '@/views/dashboard/content/vault-log/index.vue'
  );
const vaultLock = () =>
  import(
    /* webpackChunkName: "dashboard", webpackPrefetch: true */ '@/views/dashboard/content/vault-lock/index.vue'
  );
const project = () =>
  import(/* webpackChunkName: "vault" */ '@/views/dashboard/content/project/index.vue');
//asset
const asset = () =>
  import(
    /* webpackChunkName: "dashboard", webpackPrefetch: true */ '@/views/dashboard/content/asset/index.vue'
  );
const assetDetail = () =>
  import(/* webpackChunkName: "asset" */ '@/views/dashboard/content/asset-detail/index.vue');
const assetUpload = () =>
  import(/* webpackChunkName: "asset" */ '@/views/dashboard/content/asset-upload/index.vue');
//disclosure
const disclosure = () =>
  import(/* webpackChunkName: "asset" */ '@/views/dashboard/content/disclosure/index.vue');
const disclosureDetail = () =>
  import(/* webpackChunkName: "asset" */ '@/views/dashboard/content/disclosure-detail/index.vue');
//company
const company = () =>
  import(
    /* webpackChunkName: "dashboard", webpackPrefetch: true */ '@/views/dashboard/content/company/index.vue'
  );
const addCompany = () =>
  import(
    /* webpackChunkName: "dashboard", webpackPrefetch: true */ '@/views/dashboard/content/add-company/index.vue'
  );
const invitedCompany = () =>
  import(
    /* webpackChunkName: "dashboard", webpackPrefetch: true */ '@/views/invited-company/index.vue'
  );
//setting
const setting = () =>
  import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/content/setting/index.vue');

//add company
const home = () =>
  import(/* webpackChunkName: "home" */ '@/views/dashboard/content/home/index.vue');
const certificateDetail = () =>
  import(/* webpackChunkName: "home" */ '@/views/dashboard/content/certificate-detail/index.vue');
const localCertificateDetail = () =>
  import(
    /* webpackChunkName: "home" */ '@/views/dashboard/content/certificate-detail-local/index.vue'
  );
// copyright
const copyright = () =>
  import(
    /* webpackChunkName: "copyright" */ '@/views/dashboard/content/copyright/project/index.vue'
  );
const copyrightIntro = () =>
  import(
    /* webpackChunkName: "copyright" */ '@/views/dashboard/content/copyright/introduce/index.vue'
  );
const globalConfig = () =>
  import(
    /* webpackChunkName: "copyright" */ '@/views/dashboard/content/copyright/global/index.vue'
  );
const copyrightDetail = () =>
  import(
    /* webpackChunkName: "copyright" */ '@/views/dashboard/content/copyright/detail/project.vue'
  );
const commitDetail = () =>
  import(
    /* webpackChunkName: "copyright" */ '@/views/dashboard/content/copyright/detail/commit.vue'
  );
// credit
const credit = () =>
  import(/* webpackChunkName: "credit" */ '@/views/dashboard/content/credit/index.vue');

const application = () =>
  import(/* webpackChunkName: "credit" */ '@/views/dashboard/content/application/index.vue');

const applicationDetail = () =>
  import(/* webpackChunkName: "credit" */ '@/views/dashboard/content/application/detail/index.vue');

const applicationFileDetail = () =>
  import(
    /* webpackChunkName: "credit" */ '@/views/dashboard/content/application/file-detail/index.vue'
  );

const gpt = () =>
  import(/* webpackChunkName: "credit" */ '@/views/dashboard/content/gpt/list/index.vue');

const gptDetail = () =>
  import(/* webpackChunkName: "credit" */ '@/views/dashboard/content/gpt/detail/index.vue');

const companyAuthProtocol = () =>
  import(/* webpackMode: "eager" */ '@/views/index/company-auth-protocol/index.vue');

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: index,
      children: [
        {
          path: '',
          component: front,
          name: 'front',
          meta: { keepAlive: true, title: '' },
        },
        {
          path: 'government',
          component: frontGovernment,
          name: 'frontGovernment',
          meta: { keepAlive: true, title: '' },
        },
        {
          path: 'partners',
          component: partner,
          name: 'partner',
          meta: { keepAlive: true, title: `${config.corporationCompany}` },
        },
        {
          path: 'user/:active?',
          component: login,
          name: 'login',
          meta: { keepAlive: true, title: '登录' },
        },
        {
          path: 'signup',
          component: signUp,
          name: 'signUp',
          meta: { keepAlive: true, title: '注册' },
        },
        {
          path: 'terms',
          component: terms,
          name: 'terms',
        },
        {
          path: 'privacy',
          component: privacy,
          name: 'privacy',
        },
        {
          path: 'in-review',
          component: inReview,
          name: 'inReview',
          meta: { title: '审核状态' },
        },
        {
          path: 'reset-pass',
          component: resetPass,
          name: 'resetPass',
          meta: { title: '重置密码' },
        },
        {
          path: 'real-auth',
          component: realAuth,
          name: 'realAuth',
          meta: { title: '实名认证' },
        },
        {
          path: 'verify-file',
          component: verifyFile,
          name: 'verifyFile',
          meta: { title: '验证中心' },
        },
        {
          path: 'disclosures/:disclosureId',
          component: disclosureReceive,
          name: 'disclosureReceive',
          meta: { title: '接收文件' },
        },
        {
          path: 'assets',
          component: assetsMap,
          name: 'assetsMap',
          meta: { keepAlive: true, title: '数字资产地图' },
        },
        {
          path: 'assets-activate/:uuid',
          component: assetsActivate,
          name: 'assetsActivate',
          meta: { keepAlive: true, title: '点亮企业节点' },
        },
        {
          path: 'assets/:uuid',
          component: assetsEnterprise,
          name: 'assetsEnterprise',
          meta: { keepAlive: true, title: '企业数字资产' },
        },
        {
          path: 'company-auth-protocol/:legalName/:company',
          component: companyAuthProtocol,
          name: 'companyAuthProtocol',
        },
      ],
    },
    {
      path: '/bigscreen',
      component: bigScreen,
      name: 'bigScreen',
    },
    {
      path: '/dashboard',
      component: dashboard,
      children: [
        {
          path: 'empty',
          component: empty,
          name: 'empty',
        },
        {
          path: 'vault',
          component: vault,
          name: 'vault',
          meta: { sideBar: 'vault', title: '商业秘密项目' },
        },
        {
          path: 'vault/log/:projectId',
          component: vaultLog,
          name: 'vaultLog',
          meta: { sideBar: 'vault', title: '商业秘密项目' },
        },
        {
          path: 'vault/unlock',
          component: vaultLock,
          name: 'vaultLock',
          meta: { sideBar: 'vault', title: '商业秘密项目' },
        },
        {
          path: 'vault/project/:projectId',
          component: project,
          name: 'project',
          meta: { sideBar: 'vault', title: '登记文件' },
        },
        {
          path: 'asset',
          component: asset,
          name: 'asset',
          meta: { sideBar: 'asset', title: '已登记的文件' },
        },
        {
          path: 'asset/detail/:asset_id',
          component: assetDetail,
          name: 'assetDetail',
          meta: { sideBar: 'asset', title: '登记文件详情' },
        },
        {
          path: 'disclosures',
          component: disclosure,
          name: 'disclosure',
          meta: { keepAlive: true, sideBar: 'disclosure', title: '文件外发' },
        },
        {
          path: 'disclosures/detail/:disclosureId',
          component: disclosureDetail,
          name: 'disclosureDetail',
          meta: { sideBar: 'disclosure', title: '文件外发' },
        },
        {
          path: 'asset_upload',
          component: assetUpload,
          name: 'assetUpload',
          meta: { sideBar: 'asset', title: '登记文件' },
        },
        {
          path: 'company/:active?',
          component: company,
          meta: { keepAlive: true, sideBar: 'company', noTitle: true },
          name: 'company',
        },
        {
          path: 'company/certificate/:certificateId/:serialNumber',
          component: certificateDetail,
          name: 'certificateDetail',
          meta: { sideBar: 'company', title: '证书详情' },
        },
        {
          path: 'company/certificate/detail',
          component: localCertificateDetail,
          name: 'localCertificateDetail',
          meta: { sideBar: 'company', title: '证书详情' },
        },
        {
          path: 'setting/:active?',
          component: setting,
          meta: { sideBar: 'setting', title: '个人中心' },
          name: 'setting',
        },
        {
          path: 'home',
          component: home,
          name: 'home',
        },
        {
          path: 'credit',
          component: credit,
          name: 'credit',
          meta: { sideBar: 'credit', title: '积分使用历史' },
        },
        {
          path: 'repos',
          component: copyright,
          name: 'copyright',
          meta: { sideBar: 'copyright', title: '超级软著项目' },
        },
        {
          path: 'repos/intro',
          component: copyrightIntro,
          name: 'copyrightIntro',
          meta: { sideBar: 'copyright', title: '超级软著项目' },
        },
        {
          path: 'repos/global/:active?',
          component: globalConfig,
          name: 'globalConfig',
          meta: { sideBar: 'copyright', title: '超级软著项目' },
        },
        {
          path: 'repos/detail/:project_id/:commit_id',
          component: commitDetail,
          name: 'commitDetail',
          meta: { sideBar: 'copyright', title: '超级软著' },
        },
        {
          path: 'repos/detail/:project_id',
          component: copyrightDetail,
          name: 'copyrightDetail',
          meta: { sideBar: 'copyright', title: '超级软著' },
        },
        {
          path: 'application',
          component: application,
          name: 'application',
          meta: { sideBar: 'application', title: '企业应用' },
        },
        {
          path: 'application/detail/:application_id/:active?',
          component: applicationDetail,
          name: 'applicationDetail',
          meta: { sideBar: 'application', noTitle: true },
        },
        {
          path: 'application/detail/file/:application_id/:file_id',
          component: applicationFileDetail,
          name: 'applicationFileDetail',
          meta: { sideBar: 'application', title: '企业应用' },
        },
        {
          path: 'gpt/list',
          component: gpt,
          name: 'gpt',
          meta: { sideBar: 'gpt', title: 'GPT' },
        },
        {
          path: 'gpt/detail/:id?',
          component: gptDetail,
          name: 'gptDetail',
          meta: { sideBar: 'gpt', title: 'GPT' },
        },
      ],
    },
    {
      path: '/add-company/:from?',
      component: addCompany,
      name: 'addCompany',
    },
    {
      path: '/invited-company',
      component: invitedCompany,
      name: 'invitedCompany',
    },
  ],
  scrollBehavior() {
    return { top: 0 };
  },
});

router.afterEach((to) => {
  if (to.meta.title) {
    document.title = to.meta.title + '  ·  ' + config.webTitle;
  } else {
    if (!to.meta.noTitle) {
      document.title = config.webTitle;
    }
  }
});

export default router;
