<template>
  <div class="join-container">
    <a-form
      ref="joinFormRef"
      :wrapper-col="{ span: 24 }"
      :model="joinForm"
      :rules="joinRules"
      autocomplete="off"
      @validate="handleValidate"
      @submit.prevent
    >
      <a-form-item class="form-item" label="" name="name">
        <a-input
          v-model:value="joinForm.name"
          size="large"
          :placeholder="i18nText('企业名称')"
          type="text"
          @keyup.enter="joinCompany"
        />
      </a-form-item>
      <a-form-item class="form-item-btn" label=" " :colon="false">
        <a-space size="middle">
          <a-button size="large" @click="backToCompanyOperation">{{ $t('返回上一步') }}</a-button>
          <a-button :loading="loading" type="primary" size="large" @click="joinCompany">
            {{ $t('加入企业') }}
          </a-button>
        </a-space>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup>
  import { reactive, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import CompanyAPI from '@/api/v1/company';
  import ErrorMessage from '@/model/errorMessage';
  import { message } from 'ant-design-vue';
  import { i18nText } from '@/lang';

  const loading = ref(false);
  const joinForm = reactive({
    name: '',
  });

  let validateCompanyName = async (_rule, value) => {
    if (value === '') {
      return Promise.reject(i18nText('请输入正确的企业名称'));
    } else {
      return Promise.resolve();
    }
  };
  const errorResponse = ref({});
  let errorResponseValidate = async (_rule) => {
    let field = ErrorMessage.getMessageFieldNameFromError(errorResponse.value);
    if (field == '') {
      return Promise.resolve();
    }
    let transMessage = ErrorMessage.getMessageFromError(errorResponse.value);
    if (_rule.field == field) {
      return Promise.reject(transMessage);
    }
    return Promise.resolve();
  };
  const joinRules = {
    name: [
      { required: true, trigger: 'blur', validator: validateCompanyName },
      { required: true, trigger: 'blur', validator: errorResponseValidate },
    ],
  };

  const joinFormRef = ref(null);
  const router = useRouter();
  const handleValidate = (...args) => {
    errorResponse.value = {};
    console.log(args);
  };

  const store = useStore();
  const joinCompany = () => {
    joinFormRef.value.validate().then(() => {
      loading.value = true;
      CompanyAPI.joinCompany(joinForm.name)
        .then((response) => {
          store.commit('company/JOIN_COMPANY', response);
          router.push({ name: 'company' });
        })
        .catch((error) => {
          message.error(ErrorMessage.getMessageFromError(error));
          // errorResponse.value = error;
          // joinFormRef.value.validate();
        })
        .finally(() => {
          loading.value = false;
        });
    });
  };

  const emit = defineEmits(['backToCompanyOperation']);
  const backToCompanyOperation = () => {
    emit('backToCompanyOperation');
  };
</script>

<style scoped>
  .form-item-btn {
    text-align: center;
  }
  .back-button {
    border: none;

    &:hover {
      background: white;
    }
  }
</style>
