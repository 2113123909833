<template>
  <div class="footer">
    <a-row type="flex" justify="space-between" align="middle">
      <a-col flex="240px">
        <img src="./images/line.png" style="width: 241px; height: 175px" />
      </a-col>
      <a-col flex="0 1 auto">
        <a-row justify="center">
          <a-typography-title style="color: white">数字知识财产</a-typography-title>
        </a-row>
      </a-col>
      <a-col flex="240px"></a-col>
    </a-row>
    <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
      <a-col flex="1 0 10px"></a-col>
      <a-col flex="0 1 1180px">
        <a-row>
          <a-col v-for="(item, index) in footers" :key="index" :span="6">
            <a-space direction="vertical" :size="12">
              <a-typography-title :level="3" style="color: white">
                {{ item.title }}
              </a-typography-title>
              <div style="height: 12px"></div>
              <a-typography-text
                v-for="(children, index) in item.children"
                :key="index"
                style="color: white"
                :class="{ clickable: itemClickable(children) }"
                @click="clickFooter(children)"
              >
                {{ children.text }}
              </a-typography-text>
            </a-space>
          </a-col>
          <a-col :span="6">
            <a-row justify="end">
              <img
                src="./images/qr_code.png"
                style="
                  width: 156px;
                  height: 156px;
                  background: #315aff;
                  border: 12px solid #315aff;
                  border-radius: 12px;
                "
              />
            </a-row>
          </a-col>
        </a-row>
      </a-col>
      <a-col flex="1 0 10px"></a-col>
    </a-row>
    <div style="height: 148px"></div>
    <a-divider style="background-color: #ffffff1a" />
    <a-row justify="center">
      <a-col>
        <a :href="icpLink" class="icp" target="_blank">沪ICP备2021016007号-3</a>
        <span style="color: white">&nbsp;| © 2021 因问科技 版权所有 保留一切权利</span>
      </a-col>
    </a-row>
    <a-row justify="center" style="display: none">
      <a-col flex="1 0 10px"></a-col>
      <a-col flex="0 1 1180px">
        <a-row justify="space-between">
          <a-col>
            <a :href="icpLink" class="icp" target="_blank">沪ICP备2021016007号-3</a>
            <span style="color: white">&nbsp;| © 2021 因问科技 版权所有 保留一切权利</span>
          </a-col>
          <a-col>
            <a-dropdown>
              <template #overlay>
                <a-menu @click="handleLocaleClick">
                  <a-menu-item key="zh">
                    <img class="locale-image" :src="require('@/assets/zh_flag.png')" />
                    简体中文
                  </a-menu-item>
                  <a-menu-item key="en">
                    <img class="locale-image" :src="require('@/assets/en_flag.png')" />
                    English
                  </a-menu-item>
                </a-menu>
              </template>
              <a-button type="text" style="color: white">
                <span style="margin-right: 10px">
                  <img :src="localeImage" class="locale-image" />
                </span>
                {{ localeText }}
                <UpOutlined />
              </a-button>
            </a-dropdown>
          </a-col>
        </a-row>
      </a-col>
      <a-col flex="1 0 10px"></a-col>
    </a-row>
    <div style="height: 18px"></div>
  </div>
</template>

<script setup>
  import { footerConfig } from './footerConfig';
  import { useRouter } from 'vue-router';
  import { UpOutlined } from '@ant-design/icons-vue';
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n';

  const store = useStore();
  const { footers } = footerConfig();
  const router = useRouter();
  const itemClickable = (item) => {
    return item.link || item.page;
  };
  const clickFooter = (item) => {
    if (item.link) {
      window.open(item.link);
    } else if (item.page) {
      router.push({ name: item.page });
    }
  };
  let icpLink = 'https://beian.miit.gov.cn';
  const i18n = useI18n();
  const localeImage = computed(() => {
    if (store.state.locale.language == 'en') {
      return require('@/assets/en_flag.png');
    } else {
      return require('@/assets/zh_flag.png');
    }
  });
  const localeText = computed(() => (store.state.locale.language == 'en' ? 'English' : '简体中文'));
  const handleLocaleClick = (e) => {
    store.commit('locale/SET_LANGUAGE', e);
    i18n.locale = e;
    // window.location.reload();
    console.log('click', e);
  };
</script>

<style scoped>
  .footer {
    position: relative;
    background: #1e1e1e;
  }
  .clickable {
    cursor: pointer;
  }
  .icp {
    color: white;
  }
  .locale-image {
    width: 16px;
    height: 16px;
    margin-bottom: 4px;
  }
</style>
