let validationCode = {
  '手机号码:': '手机号码:',
  '验证码已发送到您的手机,10分钟内有效': '验证码已发送到您的手机,10分钟内有效',
  '您正在使用手机短信验证': '您正在使用手机短信验证',
  '获取验证码': '获取验证码',
  '6位数字': '6位数字',
};

let sidebars = {
  '数字知识产权': '数字知识产权',
  '商业秘密保险箱': '商业秘密保险箱',
  '企业中心': '企业中心',
  '个人中心': '个人中心',
  '企业应用': '企业应用',
  '积分管理': '积分管理',
  '超级软著': '超级软著',
};

let navbarTexts = {
  '区块链浏览器': '区块链浏览器',
  '文件验证中心': '文件验证中心',
  '搜索数字指纹或者DNA': '搜索数字指纹或者DNA',
  '计算文件数字指纹并搜索': '计算文件数字指纹并搜索',
  '登录/注册': '登录/注册',
  '个人中心': '个人中心',
  '退出账户': '退出账户',
  '未绑定': '未绑定',
  '是否确认退出？': '是否确认退出？',
};

let login = {
  '账户登录': '账户登录',
  '手机号或密码错误': '手机号或密码错误',
  '用户名或密码错误': '用户名或密码错误',
  '请输入登录密码': '请输入登录密码',
  '登录': '登录',
  '忘记密码': '忘记密码',
  '账户注册': '账户注册',
  '请输入密码': '请输入密码',
  '请再次输入密码': '请再次输入密码',
  '请输入姓名（可选）': '请输入姓名（可选）',
  '请输入手机验证码': '请输入手机验证码',
  '我已阅读并同意': '我已阅读并同意',
  '用户协议': '用户协议',
  '注册用户': '注册用户',
  '请输入正确的手机号码': '请输入正确的手机号码',
  '两次密码输入不一致': '两次密码输入不一致',
  '请输入验证码': '请输入验证码',
  '请先阅读并同意用户协议': '请先阅读并同意用户协议',
  '重置密码': '重置密码',
  '要求：8位以上包含特殊字符、大小写字母、数字': '要求：8位以上包含特殊字符、大小写字母、数字'
};

let verifyFileTexts = {
  '文件验证中心': '文件验证中心',
  '选择原始文件：': '选择原始文件：',
  '此处选择原始文件': '此处选择原始文件',
  '选择': '选择',
  '数字产权证明：': '数字产权证明：',
  '此处选择后缀名为“pdf”的产权证明文件': '此处选择后缀名为“pdf”的产权证明文件',
  '数字产权证明可证明数字文件（数据）的登记时间、数据完整性和数据登记者身份。通过数字产权验证的文件，证明原始文件从登记时刻起已经存在、内容完整未被篡改，并且由产权证明文件提供者进行登记。':
      '数字产权证明可证明数字文件（数据）的登记时间、数据完整性和数据登记者身份。通过数字产权验证的文件，证明原始文件从登记时刻起已经存在、内容完整未被篡改，并且由产权证明文件提供者进行登记。',
  '验证成功': '验证成功',
  '验证失败': '验证失败',
  '该文件未通过验证，可能存在真实性风险！': '该文件未通过验证，可能存在真实性风险！',
  '文件数字指纹': '文件数字指纹',
  '数字资产DNA': '数字资产DNA',
  '区块链地址': '区块链地址',
  '企业名称': '企业名称',
  '产权证明文件有效期（截止日期之前）': '产权证明文件有效期（截止日期之前）',
  '返回': '返回',
  '验证文件错误': '验证文件错误',
  '错误提示：': '错误提示：',
  '请选择请求文件': '请选择请求文件',
  '请选择证明文件': '请选择证明文件',
};

let auth = {
  '管理员正在审核,请稍候...': '管理员正在审核,请稍候...',
  '您已申请加入': '您已申请加入',
  '管理员拒绝申请': '管理员拒绝申请',
  '请联系管理员后再次尝试加入': '请联系管理员后再次尝试加入',
  '要求：': '要求：',
  '请上传清晰的身份证原件正、反面照片，系统将自动识别证件信息。':
      '请上传清晰的身份证原件正、反面照片，系统将自动识别证件信息。',
  '请使用与手机号一致的身份实名。': '请使用与手机号一致的身份实名。',
  '图片的具体需求：': '图片的具体需求：',
  '1、图片需要小于3M': '1、图片需要小于3M',
  '2、图像格式：JPEG、JPG、PNG、BMP、GIF': '2、图像格式：JPEG、JPG、PNG、BMP、GIF',
  '身份证人像面': '身份证人像面',
  '点击上传': '点击上传',
  '身份证国徽面': '身份证国徽面',
  '认证': '认证',
  '企业名称': '企业名称',
  '返回上一步': '返回上一步',
  '加入企业': '加入企业',
  '请输入正确的企业名称': '请输入正确的企业名称',
  '营业执照：': '营业执照：',
  '请上传彩色原件或加盖企业公章的复印件。': '请上传彩色原件或加盖企业公章的复印件。',
  '企业名称：': '企业名称：',
  '营业执照号：': '营业执照号：',
  '法定代表人：': '法定代表人：',
  '企业法人线上快速审核通道': '企业法人线上快速审核通道',
  '其他方式审核': '其他方式审核',
  '步骤1：': '步骤1：',
  '请打印企业申请表,打印后填写相关信息并签字盖章,然后拍照或彩色扫描后上传':
      '请打印企业申请表,打印后填写相关信息并签字盖章,然后拍照或彩色扫描后上传',
  '下载企业认证申请表': '下载企业认证申请表',
  '步骤2：': '步骤2：',
  '上传相关申请表': '上传相关申请表',
  '上传组织代码证（可选）': '上传组织代码证（可选）',
  '文件过大，限制3M以内': '文件过大，限制3M以内',
  '请先上传营业执照': '请先上传营业执照',
  '实名认证': '实名认证',
  '加入/创建企业': '加入/创建企业',
  '您还需选择以下操作': '您还需选择以下操作',
  '创建企业': '创建企业',
};

let errorMessage = {
  '您的积分不足，请先充值': '您的积分不足，请先充值',
  用户已存在: '用户已存在',
  '企业不存在，可能正在审核中': '企业不存在，可能正在审核中',
  '文件已存在, 请重新选择': '文件已存在, 请重新选择',
  '保险箱金钥已过期': '保险箱金钥已过期',
  '项目已存在，请修改项目名': '项目已存在，请修改项目名',
  '用户不存在': '用户不存在',
  '原始密码错误': '原始密码错误',
  '操作太频繁了，请稍后再试': '操作太频繁了，请稍后再试',
  'token已过期，请重新输入邮箱': 'token已过期，请重新输入邮箱',
  '数字资产ID不正确': '数字资产ID不正确',
  '原始文件与证明文件不匹配': '原始文件与证明文件不匹配',
  '证明文件不存在，可能已失效': '证明文件不存在，可能已失效',
  '证明文件格式错误': '证明文件格式错误',
  '实名认证失败，请核对后重新输入': '实名认证失败，请核对后重新输入',
  '手机验证码错误，请核对后重新输入': '手机验证码错误，请核对后重新输入',
  '验证码已过期，请重新输入': '验证码已过期，请重新输入',
  '验证码请求过于频繁，请一分钟后再试': '验证码请求过于频繁，请一分钟后再试',
  '验证码未发送，请先发送验证码': '验证码未发送，请先发送验证码',
  '企业已存在': '企业已存在',
  '你已加入企业': '你已加入企业',
  '你的账号已被冻结': '你的账号已被冻结',
  '身份信息错误，请检查后重新输入': '身份信息错误，请检查后重新输入',
  '图像识别失败，可能是图片模糊导致': '图像识别失败，可能是图片模糊导致',
  '手机实名身份和身份证信息校验不一致': '手机实名身份和身份证信息校验不一致',
  '企业法人与营业执照信息不符': '企业法人与营业执照信息不符',
  '营业执照未上传': '营业执照未上传',
  '身份证已过期': '身份证已过期',
  '图片识别失败，可能是图片模糊导致': '图片识别失败，可能是图片模糊导致',
  '身份证识别失败，可能是图片模糊导致': '身份证识别失败，可能是图片模糊导致',
  '身份证国徽面照片识别失败,请重新拍摄并上传': '身份证国徽面照片识别失败,请重新拍摄并上传',
  '身份证人像面照片识别失败,请重新拍摄并上传': '身份证人像面照片识别失败,请重新拍摄并上传',
  '营业执照识别失败,请重新拍摄并上传': '营业执照识别失败,请重新拍摄并上传',
  '无效验证码': '无效验证码',
  '必须上传正面和反面': '必须上传正面和反面',
  '填写的身份信息不正确': '填写的身份信息不正确',
  '身份证信息校验不通过，可能与手机号实名信息不符':
      '身份证信息校验不通过，可能与手机号实名信息不符',
  '您已加入企业': '您已加入企业',
  '法人信息与营业执照信息不符': '法人信息与营业执照信息不符',
  '应用已存在': '应用已存在',
  '产权证明证书解析错误': '产权证明证书解析错误',
  '产权证明验证失败，与原始内容不一致': '产权证明验证失败，与原始内容不一致',
  '超出限制长度，请重新输入': '超出限制长度，请重新输入',
  '内容太短，请重新输入': '内容太短，请重新输入',
  '用户未实名认证，请先完成实名认证': '用户未实名认证，请先完成实名认证',
};

let files = {
  '创意作品 （视听文学作品）': '创意作品 （视听文学作品）',
  '创意设计 （品牌徽标、图案、织物、建筑等）': '创意设计 （品牌徽标、图案、织物、建筑等）',
  '工业品外观设计 （技术原理图、详图、工艺流程等）':
      '工业品外观设计 （技术原理图、详图、工艺流程等）',
  '代码 （用于软件、应用程序、游戏等）': '代码 （用于软件、应用程序、游戏等）',
  '研究（实验室记录、报告等发现）': '研究（实验室记录、报告等发现）',
  '数据（AI建模、基因测序等）': '数据（AI建模、基因测序等）',
  '有数字签名的文件（合同、信件、证书等）': '有数字签名的文件（合同、信件、证书等）',
  '其它 （例如，损害资料或未签名文件）': '其它 （例如，损害资料或未签名文件）',
};

let enterprises = {
  '企业成员': '企业成员',
  '超级管理员': '超级管理员',
  '管理员': '管理员',
  '保密员': '保密员',
  '企业数字身份': '企业数字身份',
  '证书上链地址': '证书上链地址',
  '证书链': '证书链',
  '证书主体': '证书主体',
  '详情': '详情',
  '证书使用记录': '证书使用记录',
  '证书详情': '证书详情',
  '证书所有者': '证书所有者',
  '颁发者': '颁发者',
  '签发日期': '签发日期',
  '失效日期': '失效日期',
  '公钥': '公钥',
  '签署时间': '签署时间',
  '签署类型': '签署类型',
  '签署内容': '签署内容',
  '操作人': '操作人',
  '签署文件': '签署文件',
  '详细信息': '详细信息',
  '证书名': '证书名',
  '签发时间': '签发时间',
  '证书DNA': '证书DNA',
  '产权签署证书': '产权签署证书',
  '企业根证书': '企业根证书',
  '成员名单': '成员名单',
  '修改权限': '修改权限',
  '冻结': '冻结',
  '解除冻结': '解除冻结',
  '冻结账号': '冻结账号',
  '是否冻结成员帐号?': '是否冻结{name}成员帐号?',
  '是否解冻成员账号?': '是否解冻{name}成员账号?',
  '解冻账号': '解冻账号',
  '成员申请': '成员申请',
  '已通过': '已通过',
  '已拒绝': '已拒绝',
  '通过': '通过',
  '不通过': '不通过',
  '姓名': '姓名',
  '角色': '角色',
  '手机号码': '手机号码',
  '成员姓名': '成员姓名',
  '申请时间': '申请时间',
  '企业用户只能看自己的数据': '企业用户只能看自己的数据',
  '保密员可以看企业里全部数据': '保密员可以看企业里全部数据',
  '企业名称': '企业名称',
  '营业执照号': '营业执照号',
  '数字资产信用分': '数字资产信用分',
  '认证中': '认证中',
  '审核未通过': '审核未通过',
  '已认证': '已认证',
  '暂无价值': '暂无价值',
  '价值极高': '价值极高',
  '价值良好': '价值良好',
  '企业信息': '企业信息',
  '数字身份': '数字身份',
  '成员管理': '成员管理',
  '密钥状态': '密钥状态',
  '已托管': '已托管',
  '密钥使用历史': '密钥使用历史',
  '密钥取消托管后，您将无法通过平台的Web服务进行文件登记操作。点击确认后将进入审核流程，稍后会有专员与您联系。确认取消托管吗？':
      '密钥取消托管后，您将无法通过平台的Web服务进行文件登记操作。点击确认后将进入审核流程，稍后会有专员与您联系。确认取消托管吗？',
  '取消托管': '取消托管',
  '审核中..': '审核中..',
  '日期': '日期',
  '上传文件': '上传文件',
  '文件类型': '文件类型',
};

let application = {
  '创建一个新项目': '创建一个新项目',
  '应用简介：': '应用简介',
  '请输入应用名称': '请输入应用名称',
  '请输入应用简介': '请输入应用简介',
  '应用信息': '应用信息',
  '应用名称': '应用名称',
  '应用描述': '应用描述',
  '应用数据': '应用数据',
  '应用状态': '应用状态',
  '应用数据详情': '应用数据详情',
  '企业应用': '企业应用',
  '创建应用': '创建应用',
  '最近更新': '最近更新',
  '未连接': '未连接',
  '连接正常': '连接正常',
  '连接异常': '连接异常',
  '数据中断': '数据中断',
  '上一次数据提交时间': '上一次数据提交时间',
  '数据提交有误': '数据提交有误',
  '请求数据': '请求数据',
  '错误消息': '错误消息',
  '监控报警': '监控报警',
  '启用短信报警': '启用短信报警。',
  '小时无数据后发送报警短信': '小时无数据后发送报警短信。',
};

let assets = {
  '已登记的文件': '已登记的文件',
  '登记文件': '登记文件',
  '登记者': '登记者',
};

let vault = {
  '文件修改历史': '文件修改历史',
  '验证手机号后进入保险箱': '验证手机号后进入保险箱',
  '验证': '验证',
  '选择文件': '选择文件',
  '数字资产名': '数字资产名',
  '还未选择文件': '还未选择文件',
  '数字指纹': '数字指纹',
  '选择文件后自动进行计算': '选择文件后自动进行计算',
  '登记类型': '登记类型',
  '设置数字资产类型': '设置数字资产类型',
  '文件描述': '文件描述',
  '请输入内容': '请输入内容',
  '登记文件': '登记文件',
  '积分扣除提醒': '积分扣除提醒',
  '您的账户中有：积分': '您的账户中有：{creditBalance} 积分',
  '本次存证将扣除您账户中': '本次存证将扣除您账户中',
  'xx积分': '{consumeCredit} 积分',
  '积分余额不足': '积分余额不足',
  '您的积分余额已不足': '您的积分余额已不足',
  '请联系系统管理员': '请联系系统管理员',
  '请选择文件': '请选择文件',
  '请输入文件名': '请输入文件名',
  '正在计算...': '正在计算...',
  '商业秘密项目': '商业秘密项目',
  '创建一个新项目': '创建一个新项目',
  '项目是与特定IP管理过程或活动相关的数据和文件的整合':
      '项目是与特定IP管理过程或活动相关的数据和文件的整合',
  '项目名称：': '项目名称：',
  '存证完成': '存证完成',
  '文件被更新': '文件被更新',
  '无文件': '无文件',
  '项目': '项目',
  '未找到搜索结果!': '未找到搜索结果!',
};

let proofs = {
  '生成中': '生成中',
  '已生成': '已生成',
  '登记新版本': '登记新版本',
  '下载文件': '下载文件',
  '产权证明': '产权证明',
  '登记信息': '登记信息',
  '所有者': '所有者',
  '区块信息': '区块信息',
  '数字指纹': '数字指纹',
  '企业数字公钥': '企业数字公钥',
  '该数字资产已在互联网法院存证，如需详细内容，请与联系。':
      '该数字资产已在互联网法院存证，如需详细内容，请与{webTitle}联系。',
  '签名信息': '签名信息',
  '企业数字签名': '企业数字签名',
  '签署内容': '签署内容',
  '企业隐私身份': '企业隐私身份',
  '下载': '下载',
  '创建人': '创建人',
  '状态': '状态',
  '失效': '失效',
  '有效': '有效',
  '生成产权证明': '生成产权证明',
  '产权证明有效期：': '产权证明有效期：',
  '生成': '生成',
  '请选择正确格式的文件': '请选择正确格式的文件',
};

let home = {
  '全网数据总量': '全网数据总量',
  '商业秘密': '商业秘密',
  '项目数量': '项目数量',
  '文件托管': '文件托管',
  '超级软著': '超级软著',
  '应用数量': '应用数量',
  '动态': '动态',
  '早安': '早安',
  '下午好': '下午好',
  '晚上好': '晚上好',
  '代码提交次数': '代码提交次数',
  '登记了数字知识产权财产': '登记了数字知识产权财产',
  '登记了商业秘密财产': ' 登记了商业秘密财产 ',
  ' 登记了超级软著,登记描述为:': ' 登记了超级软著,登记描述为:',
  '创建了商业秘密项目': '',
  '创建了超级软著项目': '',
  ' 更新了商业秘密财产 ': ' 更新了商业秘密财产 ',
  '登记新文件': '登记新文件',
  '企业内登记的数据知识产权总量，包括全部类型的数字知识产权登记的总数。':
      '企业内登记的数据知识产权总量，包括全部类型的数字知识产权登记的总数。',
  '企业内全部商业秘密项目中登记的文件总数量，包括已托管和未托管的全部文件。':
      '企业内全部商业秘密项目中登记的文件总数量，包括已托管和未托管的全部文件。',
  '企业内全部超级软著项目的代码提交次数。': '企业内全部超级软著项目的代码提交次数。',
  '通过API对接的企业内全部应用的数据提交次数。': '通过API对接的企业内全部应用的数据提交次数。',
};

let copyRights = {
  '提交记录': '提交记录',
  '编辑者': '编辑者',
  '提交时间': '提交时间',
  '提交说明': '提交说明',
  '存证信息': '存证信息',
  '数字资产登记时间': '数字资产登记时间',
  '企业数字公钥': '企业数字公钥',
  '数字签名': '数字签名',
  '零知识证明': '零知识证明',
  '项目类型': '项目类型',
  '项目简介': '项目简介',
  '请输入项目简介': '请输入项目简介',
  '项目名称': '项目名称',
  '请输入项目名称': '请输入项目名称',
  '超级软著项目': '超级软著项目',
  '创建一个项目': '创建一个项目',
  '项目名称长度限制为30字符以内': '项目名称长度限制为30字符以内',
  '项目简介长度限制为200字符以内': '项目简介长度限制为200字符以内',
};

let credits = {
  '积分使用历史': '积分使用历史',
  '可用积分：': '可用积分：',
  人员筛选: '人员筛选',
  '重置': '重置',
  '登记编号：': '登记编号：',
  '申请状态：已同意': '申请状态：已同意',
  '申请状态：处理中': '申请状态：处理中',
  '申请状态：已拒绝': '申请状态：已拒绝',
  '成员文件存证': '成员文件存证',
  '文件存证': '文件存证',
  '所属项目：': '所属项目：',
  '登记人：': '登记人：',
  '消耗积分': '消耗积分',
  '充值平台：支付宝': '充值平台：支付宝',
  '充值金额': '充值金额',
  '积分充值': '积分充值',
  '充值账号：': '充值账号：',
  '成员充值': '成员充值',
  '用户名称：': '用户名称：',
  '被充值账号：': '被充值账号：',
};

let settings = {
  '基本资料': '基本资料',
  '姓名': '姓名',
  '手机': '手机',
  '修改': '修改',
  '修改手机号': '修改手机号',
  '新手机号': '新手机号',
  '请输入新的手机号': '请输入新的手机号',
  '输入你的验证码': '输入你的验证码',
  '你的手机号已成修改完成,当前绑定手机号是:': '你的手机号已成修改完成,当前绑定手机号是:',
  '修改姓名': '修改姓名',
  '请输入用户名!': '请输入用户名!',
  '验证旧手机': '验证旧手机',
  '更改手机': '更改手机',
  '完成': '完成',
  '下一步': '下一步',
  '请输入发送至旧手机的验证码': '请输入发送至旧手机的验证码',
  '请输入正确的手机号码': '请输入正确的手机号码',
  '号码与现在号码相同': '号码与现在号码相同',
  '请输入发送至新手机的验证码': '请输入发送至新手机的验证码',
  '旧密码': '旧密码',
  '请输入旧密码': '请输入旧密码',
  '新密码': '新密码',
  '请输入新密码': '请输入新密码',
  '确认新密码': '确认新密码',
  '请再次输入新密码': '请再次输入新密码',
  '旧密码不正确': '旧密码不正确',
  '新密码不能小于7位': '新密码不能小于7位',
  '旧密码和新密码不能相同': '旧密码和新密码不能相同',
  '两次输入密码不一致': '两次输入密码不一致',
  '个人中心': '个人中心',
  '个人信息': '个人信息',
  '修改密码': '修改密码',
  '不能修改只读账户信息': '用户信息由AD服务管理，无法在此处进行修改'
};

let globalTexts = {
  '确定': '确定',
  '创建者': '创建者',
  '文件名': '文件名',
  '类型': '类型',
  '登记时间': '登记时间',
  '登记时间：': '登记时间：',
  '点击查看': '点击查看',
  '已复制': '已复制',
  '创建时间': '创建时间',
  '创建': '创建',
  '更新时间': '更新时间',
  '数字资产DNA': '数字资产DNA',
  '过期时间': '过期时间',
  '操作': '操作',
  '验证码': '验证码',
  '请输入验证码': '请输入验证码',
  '发送验证码': '发送验证码',
  '取消': '取消',
  '请输入正确的验证码': '请输入正确的验证码',
  '创建项目': '创建项目',
  '存证中': '存证中',
  '请输入手机号': '请输入手机号',
  '请输入用户名': '请输入用户名',
  '请输入用户名或手机号': "用户名/手机号码",
  '密码必须包含数字和字母': '密码必须包含数字和字母',
  '密码不正确': '密码不正确',
};

export default {
  ...validationCode,
  ...sidebars,
  ...navbarTexts,
  ...login,
  ...verifyFileTexts,
  ...auth,
  ...errorMessage,
  ...files,
  ...assets,
  ...vault,
  ...proofs,
  ...copyRights,
  ...credits,
  ...settings,
  ...enterprises,
  ...application,
  ...home,
  ...globalTexts,
  xxx: 'xxx',
};
