<template>
  <a-row type="flex" justify="space-between" align="middle" style="height: 80px">
    <a-col flex="15px"></a-col>
    <a-col flex="auto">
      <a-row type="flex" justify="space-between" align="middle" style="height: 80px">
        <a-col v-if="config.frontPageVersion == 'default'">
          <a-typography-title :level="3" :style="{ color: color }" @click="clickWebTitle">
            {{ webTitle }}
          </a-typography-title>
        </a-col>
        <a-col v-else>
          <img src="./images/xian-logo.png" style="width: 230px; height: 40px" />
        </a-col>
        <a-col>
          <a-button style="background: transparent; border: none" @click="showDrawer">
            <template #icon>
              <MenuOutlined style="font-size: 24px" :style="{ color: color }" />
            </template>
          </a-button>
        </a-col>
      </a-row>
    </a-col>
    <a-col flex="15px"></a-col>
    <a-drawer :width="500" :placement="placement" :visible="visible" height="500" @close="onClose">
      <a-space direction="vertical" style="width: 100%">
        <a-button
          v-for="item in menuItems"
          :key="item.key"
          type="link"
          class="side-menu-item"
          style="width: 100%; height: 60px; color: black; text-align: left"
          @click="sideItemClicked(item.key)"
        >
          {{ item.title }}
        </a-button>
        <div style="height: 40px"></div>
        <a-row justify="center">
          <a-button type="primary" size="middle" @click="clickLogin">登录/注册</a-button>
        </a-row>
      </a-space>
    </a-drawer>
  </a-row>
</template>

<script setup>
  import { MenuOutlined } from '@ant-design/icons-vue';
  import config from '/config';
  import { computed, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';

  const props = defineProps({
    showLogin: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    showUser: {
      type: Boolean,
      default: true,
    },
    isBlack: {
      type: Boolean,
      default: false,
    },
  });
  // const store = useStore();
  // const router = useRouter();
  const webTitle = config.webTitle;
  const color = computed(() => (props.isBlack ? 'black' : 'white'));

  const placement = ref('top');
  const visible = ref(false);
  const showDrawer = () => {
    visible.value = true;
  };
  const onClose = () => {
    visible.value = false;
  };
  const menuItems = ref([
    { key: 'asset', title: '服务基座' },
    { key: 'vault', title: '解决方案' },
    { key: 'company', title: '河图创投' },
    { key: 'setting', title: '合作伙伴' },
  ]);
  if (config.frontPageVersion == 'xian') {
    let array = [
      { key: 'service', title: '首页' },
      { key: 'partner', title: '合作伙伴' },
      { key: 'block', title: '资产网络' },
      { key: 'verify', title: '资产核验中心' },
    ];
    menuItems.value = array;
  }
  const sideItemClicked = (name) => {
    console.log(name);
    if (name == 'block') {
      let href = config.chainBrowserURL + config.chainBrowserHome;
      window.open(href);
    } else if (name == 'verify') {
      visible.value = false;
      router.push({ name: 'verifyFile' });
    } else if (name == 'partner') {
      visible.value = false;
      router.push({ name: 'partner' });
    } else if (name == 'service') {
      visible.value = false;
      router.push({ path: '/' });
    }
  };
  const store = useStore();
  const router = useRouter();
  const clickWebTitle = () => {
    if (store.state.user.phonenumber) {
      router.push({ name: config.defaultPage });
    } else {
      window.location.reload();
    }
  };
  const clickLogin = () => {
    if (store.state.user.phonenumber) {
      router.push({ name: config.defaultPage });
    } else {
      router.push({ name: 'login', params: { active: 'login' } });
    }
  };
</script>

<style lang="less" scoped>
  .chain-link {
    color: white;
  }
  .chain-link:hover {
    color: @text-selection-bg;
  }
</style>
