import { createApp, h } from 'vue';
import App from './App.vue';
import Antd from 'ant-design-vue';
import { message, notification } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less'; // 引入全局的组件样式
import router from './router';
import store from './store';
import { ApiPromise } from '@/api/api-promise';
import filters from '@/filters/index.js';
import ErrorMessage from '@/model/errorMessage';
import config from '/config';
import i18n from '@/lang';
import VueParticles from 'vue-particles';

const app = createApp({
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  },
  render: () => h(App),
});
app.use(Antd);
app.use(router);
app.use(store);
app.use(i18n);
app.use(VueParticles);

app.config.globalProperties.$filters = filters;
app.config.globalProperties.$message = message;
app.config.globalProperties.$notification = notification;

(function () {
  File.prototype.arrayBuffer = File.prototype.arrayBuffer || myArrayBuffer;
  Blob.prototype.arrayBuffer = Blob.prototype.arrayBuffer || myArrayBuffer;

  function myArrayBuffer() {
    return new Promise((resolve) => {
      let fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
      };
      fr.readAsArrayBuffer(this);
    });
  }
})();

window.confidentialNotified = false;
// Set the default API error handler to use message
ApiPromise.defaultOnReject = (err) => {
  if (err.response && err.response.status == 500) {
    return;
  }
  try {
    let showMessage = ErrorMessage.getMessageFromError(err);
    if (showMessage) {
      message.error(showMessage);
    } else {
      message.error(err.toString());
    }
  } catch (e) {
    message.error(e.toString());
  }
};

ApiPromise.serverErrorOnReject = (err) => {
  switch (err.response.status) {
    case 500:
      message.error('服务器错误 请稍后重试');
      break;
    case 504:
      message.error('网络请求超时');
      break;
    default:
      message.error(`网络异常 请稍后重试 ${err.response.status}`);
  }
};

window.CHAIN_BROWSER_URL = config.chainBrowserURL;
window.CHAIN_BROWSER_HOME = config.chainBrowserHome;
window.PROOF_RESOUCE_URL = config.baseAPI;
window.SUPPORT_EMAIL = config.supportEmail;

app.config.globalProperties.$appGlobal = {
  CHAIN_BROWSER_URL: window.CHAIN_BROWSER_URL,
  CHAIN_BROWSER_HOME: window.CHAIN_BROWSER_HOME,
  PROOF_RESOUCE_URL: window.PROOF_RESOUCE_URL,
  SUPPORT_EMAIL: window.SUPPORT_EMAIL,
  TABLE_SIZE: config.tableSize,
};

app.mount('#app');
