import v2 from './v2';

export default {
  login(phoneNumber, password) {
    return v2.post('/users', {
      login_id: phoneNumber,
      password: password,
    });
  },
  codeLogin(phoneNumber, code) {
    return v2.post('/users', {
      login_id: phoneNumber,
      validation_code: code,
    });
  },
  commitUserAuth(card_no, name) {
    return v2.post('/users/verified-token', { card_no: card_no, name: name });
  },
  ocrIDInsignia(imageId) {
    return v2.post('/users/verified-token', { image_id: imageId, side: 'back' });
  },
};
