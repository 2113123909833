<template>
  <a-row justify="center" style="margin: 60px">
    <a-space direction="vertical" style="width: 600px">
      <a-typography-title :level="3">企业认证协议</a-typography-title>
      <p style="text-indent: 28px; font-size: 16px">{{ content }}</p>
      <p style="text-indent: 28px; font-size: 16px">{{ secondContent }}</p>
    </a-space>
  </a-row>
</template>

<script setup>
  import { computed } from 'vue';
  import { useRoute } from 'vue-router';
  import config from '/config';
  const route = useRoute();
  const content = computed(
    () => `本人【${route.params.legalName}】是【${route.params.company}】的法人，
      代表本企业/机构向【${config.webTitle}(https://${window.location.host}】（以下简称平台）提交实名账号开通申请，承诺提交真实信息用于平台的企业/机构身份审核，
      该实名账号在平台上的全部操作行为，视作合法有效的本企业/机构行为。
     `,
  );
  const secondContent =
    '授权平台为本企业/机构颁发并托管企业数字证书，用于在数字签名中代表本企业/机构的身份。在平台的功能范围内，\n     通过数字证书生成数字签名、对相关活动进行授权的行为，视作合法有效的本企业/机构行为。';
</script>
