<template>
  <div class="front-page">
    <div class="header-blue-bg">
      <navbar></navbar>
      <div style="height: 60px"></div>
      <a-row justify="space-between" style="flex-wrap: nowrap">
        <a-col flex="130px">
          <a-space direction="vertical">
            <div style="height: 70px"></div>
            <img src="./images/xiushi_1.png" style="width: 130px; height: 73px" />
          </a-space>
        </a-col>
        <a-col flex="0 1 850px">
          <a-row justify="space-between" style="width: 100%">
            <a-typography-title style="text-align: center; color: white; font-size: 52px">
              提供数字知识财产从创造、使用、保护到管理的全链条服务
            </a-typography-title>
          </a-row>
        </a-col>
        <a-col flex="130px"></a-col>
      </a-row>
      <div style="height: 35px"></div>
    </div>
    <div class="image-container">
      <div class="blue-bg"></div>
      <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
        <a-col flex="1 0 10px"></a-col>
        <a-col flex="0 1 1180px">
          <img src="./images/shouping.png" style="width: 100%; object-fit: cover" />
        </a-col>
        <a-col flex="1 0 10px"></a-col>
      </a-row>
    </div>
    <div style="height: 36px"></div>
    <a-row justify="center">
      <img src="./images/xiushi_3.png" style="width: 60px; height: 13px" />
    </a-row>
    <div style="height: 154px"></div>
    <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
      <a-col flex="1 0 10px"></a-col>
      <a-col flex="0 1 1180px">
        <a-row :gutter="56">
          <a-col
            v-for="(item, index) in advantages"
            :key="index"
            :xs="24"
            :sm="8"
            :md="8"
            :lg="8"
            :xl="8"
          >
            <a-space align="start">
              <img :src="item.image" style="width: 66px; height: 66px" />
              <a-space direction="vertical">
                <div style="height: 40px"></div>
                <a-typography-title :level="3">{{ item.title }}</a-typography-title>
                <a-typography-text style="font-size: 20px">
                  {{ item.description }}
                </a-typography-text>
              </a-space>
            </a-space>
          </a-col>
        </a-row>
      </a-col>
      <a-col flex="1 0 10px"></a-col>
    </a-row>
    <div style="height: 150px"></div>
    <a-row type="flex" justify="space-around" style="flex-wrap: nowrap">
      <a-col flex="1 0 10px"></a-col>
      <a-col flex="0 1 1180px">
        <a-row justify="space-between" :gutter="120">
          <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="11">
            <a-space direction="vertical" :size="22">
              <img src="./images/shuzizhishicaichan_1.png" style="width: 41px; height: 38px" />
              <a-typography-title>数字知识财产</a-typography-title>
              <a-typography-text style="font-size: 20px">
                知识财产是人类在社会实践中创造的智力劳动成果。而在数字化时代,
                海量知识财产（数据）的创造、管理和使用面临着前所未有的挑战。
              </a-typography-text>
              <a-typography-text style="font-size: 20px">
                数字知识产权服务平台以“数字化确权”作为知识财产保护的起点,
                快速为企业及个人建立知识财产保险箱, 在保护知识产权不被侵犯的前提下,
                通过隐私计算、人工智能和区块链技术促进知识财产价值的全面释放。
                数字知识产权服务平台降低了知识财产生产、使用和保护的成本，在更大范围内促进知识财产的创造和流通，从而提升整个社会的创新能力。
              </a-typography-text>
            </a-space>
          </a-col>
          <a-col
            :xs="{ span: 24, offset: 0 }"
            :sm="{ span: 12, offset: 0 }"
            :md="{ span: 12, offset: 0 }"
            :lg="{ span: 12, offset: 0 }"
            :xl="{ span: 12, offset: 1 }"
          >
            <img src="./images/shuzizhishicaichan.png" style="width: 100%; object-fit: cover" />
          </a-col>
        </a-row>
      </a-col>
      <a-col flex="1 0 10px"></a-col>
    </a-row>
    <div style="height: 180px"></div>
    <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
      <a-col flex="1 0 10px"></a-col>
      <a-col flex="0 1 1180px">
        <a-row justify="space-between" :gutter="120">
          <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="11">
            <a-space direction="vertical" :size="22">
              <img src="./images/shuzihuaquequan_1.png" style="width: 40px; height: 40px" />
              <a-typography-title>数字化确权</a-typography-title>
              <a-typography-text style="font-size: 20px">
                数字化确权通过技术手段为数据生成不可篡改的证据，证明数据在某一个时间点存在。用于防止数据的滥用和盗用，解决相关法律纠纷。
              </a-typography-text>
              <a-typography-text style="font-size: 20px">
                和传统的知识产权保护手段相比，数字化确权具有保护隐私、低成本和快捷的优势。可以方便且安全地嵌入数据生产的流程中，提供更细粒度的产权证明和制造过程存证。
              </a-typography-text>
              <a-typography-text style="font-size: 20px">
                数字化确权是传统知识产权保护手段的有力补充，在知识产权的前置确权阶段发挥作用，为传统知识产权的确权和维权提供更有力的保障。
              </a-typography-text>
            </a-space>
          </a-col>
          <a-col
            :xs="{ span: 24, offset: 0 }"
            :sm="{ span: 12, offset: 0 }"
            :md="{ span: 12, offset: 0 }"
            :lg="{ span: 12, offset: 0 }"
            :xl="{ span: 12, offset: 1 }"
          >
            <img src="./images/shuzihuaquequan.png" style="width: 100%; object-fit: cover" />
          </a-col>
        </a-row>
      </a-col>
      <a-col flex="1 0 10px"></a-col>
    </a-row>
    <div style="height: 198px"></div>
    <div class="gray-container">
      <a-space direction="vertical" style="width: 100%" :size="96">
        <a-row type="flex" justify="space-between" style="flex-wrap: nowrap" align="bottom">
          <a-col flex="130px"></a-col>
          <a-col flex="auto">
            <a-row justify="center">
              <a-typography-title>哪些财产需要被保护?</a-typography-title>
            </a-row>
          </a-col>
          <a-col flex="130px">
            <a-space direction="vertical">
              <div style="height: 36px"></div>
              <img src="./images/xiushi_2.png" style="width: 130px; height: 73px" />
            </a-space>
          </a-col>
        </a-row>
        <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
          <a-col flex="1 0 10px"></a-col>
          <a-col flex="0 1 1180px">
            <a-row justify="center" :gutter="40">
              <a-col v-for="(item, index) in needProtecteds" :key="index" :span="6">
                <a-space direction="vertical">
                  <div v-if="index % 2 !== 0" style="height: 40px"></div>
                  <img :src="item.image" style="width: 100%" />
                  <div style="height: 20px"></div>
                  <a-typography-title :level="3">{{ item.title }}</a-typography-title>
                  <a-typography-text style="font-size: 20px">
                    {{ item.description }}
                  </a-typography-text>
                </a-space>
              </a-col>
            </a-row>
          </a-col>
          <a-col flex="1 0 10px"></a-col>
        </a-row>
      </a-space>
      <div style="height: 200px"></div>
    </div>
    <div style="height: 176px"></div>
    <a-space direction="vertical" style="width: 100%" :size="60">
      <a-row justify="center">
        <a-typography-title>解决方案</a-typography-title>
      </a-row>
      <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
        <a-col flex="1 0 10px"></a-col>
        <a-col flex="0 1 1180px">
          <a-row justify="space-between" :gutter="24">
            <a-col
              v-for="(item, index) in solutions"
              :key="index"
              :xs="24"
              :sm="24"
              :md="6"
              :lg="6"
              :xl="6"
            >
              <a-card
                style="
                  border-radius: 24px;
                  border: 2px solid #315aff;
                  box-shadow: 0px 3px 0px #315aff;
                "
              >
                <a-space direction="vertical" style="background: #f5f5f5; border-radius: 22px">
                  <div style="height: 26px"></div>
                  <a-row type="flex" style="flex-wrap: nowrap">
                    <a-col flex="16px"></a-col>
                    <a-col flex="0 1 auto">
                      <a-typography-title :level="3">{{ item.title }}</a-typography-title>
                      <a-row style="height: 54px">
                        <a-typography-text style="font-size: 16px">
                          {{ item.description }}
                        </a-typography-text>
                      </a-row>
                    </a-col>
                    <a-col flex="16px"></a-col>
                  </a-row>
                  <img :src="item.image" style="width: 100%" />
                </a-space>
              </a-card>
            </a-col>
          </a-row>
        </a-col>
        <a-col flex="1 0 10px"></a-col>
      </a-row>
    </a-space>
    <div style="height: 200px"></div>
    <a-row justify="center">
      <a-typography-title>“数字钱包”</a-typography-title>
    </a-row>
    <div style="height: 76px"></div>
    <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
      <a-col flex="1 0 10px"></a-col>
      <a-col flex="0 1 1180px">
        <a-row>
          <a-col :span="12">
            <img src="./images/shuziqianbao.png" style="width: 80%; object-fit: cover" />
          </a-col>
          <a-col :span="10">
            <a-space direction="vertical" :size="18">
              <img src="./images/yinhao.png" style="width: 68px; height: 42px" />
              <a-typography-text style="font-size: 24px">
                使用密码学技术为企业生成唯一的数字身份。使用数字身份对企业内数字资产进行签名，可证明企业对数字资产的所有权。
                企业数字身份保管于基座内置的专用硬件加密芯片，没有密钥丢失风险。全套签名方案使用国密算法，符合国家安全标准要求。
                高性能软硬件一体架构设计，支持海量高并发的企业数字资产快速签名认证，有效避免延迟认证带来的侵权风险。
              </a-typography-text>
            </a-space>
          </a-col>
        </a-row>
      </a-col>
      <a-col flex="1 0 10px"></a-col>
    </a-row>
    <div style="height: 128px"></div>
    <front-footer></front-footer>
  </div>
</template>

<script setup>
  import Navbar from '../front-navbar/web';
  import frontFooter from '../front-footer/web';
  import { frontConfig } from './frontConfig';
  const { advantages, solutions, needProtecteds } = frontConfig();
</script>

<style scoped>
  .front-page {
    position: relative;
    overflow: hidden;
  }
  .header-blue-bg {
    background: #456ff4;
  }
  .image-container {
    position: relative;
  }
  .blue-bg {
    background: #456ff4;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
  .gray-container {
    background: #fafafa;
  }
  @media only screen and (min-width: 1200px) {
    .blue-bg {
      height: 520px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .blue-bg {
      height: calc(100vw * 520 / 1180);
    }
  }
</style>
