<template>
  <div class="front-page">
    <div v-if="showDefaultFrontPage">
      <web class="web"></web>
      <mobile class="mobile"></mobile>
    </div>
    <div v-else>
      <xian-web class="web"></xian-web>
      <xian-middle class="middle"></xian-middle>
      <xian-mobile class="mobile"></xian-mobile>
    </div>
  </div>
</template>

<script>
  export default {
    beforeRouteEnter(to, from, next) {
      if (config.frontPageAvailable) {
        next();
      } else {
        next({ name: 'login', params: { active: 'login' } });
      }
    },
  };
</script>

<script setup>
  import { computed } from 'vue';
  import mobile from './mobile';
  import web from './web';
  import xianWeb from '@/views/index/front-xian/front/web';
  import xianMiddle from '@/views/index/front-xian/front/middleWidth';
  import xianMobile from '@/views/index/front-xian/front/mobile';
  import config from '/config';
  const showDefaultFrontPage = computed(() => config.frontPageVersion == 'default');
</script>

<style scoped>
  .front-page {
    position: relative;
  }
  .web {
    display: block;
  }
  .middle {
    display: none;
  }
  .mobile {
    display: none;
  }
  @media only screen and (max-width: 920px) {
    .web {
      display: none;
    }
    .middle {
      display: block;
    }
    .mobile {
      display: none;
    }
  }
  @media only screen and (max-width: 720px) {
    .web {
      display: none;
    }
    .middle {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
</style>
