<template>
  <div style="text-align: center">
    <div style="font-size: 32px; margin-top: 80px; margin-bottom: 30px">{{ name }}</div>
    <a-button v-if="!activated" type="primary" @click="activate">点亮</a-button>
    <a-button v-else class="activated" @click="activate">已点亮</a-button>
  </div>
</template>

<script setup>
  import axios from 'axios';
  import { ref } from 'vue';
  import { useRoute } from 'vue-router';

  const route = useRoute();
  const uuid = route.params.uuid;
  const baseURL = 'https://ip-enterprise-api.yuanben.site';

  const name = ref('');
  const activated = ref(false);
  axios.get('v1/enterprise', { baseURL, params: { uuid: uuid } }).then((res) => {
    name.value = res.data.name;
    activated.value = res.data.activated;
  });

  const activate = async () => {
    const res = await axios.get(`v1/enterprise/activate/${uuid}`, { baseURL });
    if (res.status === 200) {
      activated.value = true;
    }
  };
</script>

<style scoped>
  .activated {
    background-color: #ff9900;
    color: white;
  }
</style>
